import { DefaultTheme } from 'styled-components';

declare module 'styled-components' {
  export interface DefaultTheme {
    palette: {
      primary: string;
      warning: string;
      danger: string;
      success: string;
      gray: string;
      gray97: string;
      gray50: string;
      gray35: string;
      gray10: string;
      white: string;
      default: string;
    };
    text: {
      default: string;
      secondary: string;
      tetriary: string;
      white: string;
    };
    background: {
      default: string;
      primary: string;
      secondary: string;
      warning: string;
      error: string;
      success: string;
      selected: string;
      danger: string;
    };
    spacing: {
      1: string;
      2: string;
      3: string;
      4: string;
      5: string;
    };
    elements: {
      line: string;
      borderRadius: string;
      spacing: string;
      transitions: string;
    };
  }
}

export const lightTheme: DefaultTheme = {
  palette: {
    primary: '#1947EC',
    warning: '#92400E',
    danger: '#B20F00',
    success: '#065F46',
    gray: '#D8D8D8',
    gray97: '#F7F7F7',
    gray50: '#808080',
    gray35: '#585858',
    gray10: '#1B1B1B',
    white: '#FFFFFF',
    default: '#404040',
  },
  text: {
    default: '#1F2937',
    secondary: '#808080',
    tetriary: '#585858',
    white: '#FFFFFF',
  },
  background: {
    default: '#F7F7F7',
    secondary: '#1D2226',
    error: '#FFF1F2',
    primary: '#EBEFFF',
    warning: '#FEF3C7',
    danger: '#FEE2E2',
    success: '#D1FAE5',
    selected: 'rgba(0,0,0,0.1)',
  },
  elements: {
    line: '#EBEBEB',
    borderRadius: '4px',
    spacing: '16px',
    transitions: 'all 0.25s ease',
  },
  spacing: {
    1: '8px',
    2: '16px',
    3: '24px',
    4: '32px',
    5: '40px',
  },
};
