
import { useState } from 'react';
import styled from 'styled-components';
import Button from '../../components/shared/Button';
import Elements from '../../components/shared/Elements';
import FormElement from '../../components/shared/FormElement';
import Icon from '../../components/shared/Icon';
import Logo, { LogoContainerStyled } from '../../components/shared/Logo/Logo';
import { useSelector } from 'react-redux';
import { loginUser } from '../../reducers/user/loginUser';
import { RootState, useAppDispatch } from '../../store';
import { Formik } from 'formik';
import * as Yup from 'yup';

const LoginPage = ()=>{
    
    const dispatch = useAppDispatch();
    const loginStatus = useSelector((state: RootState) => state.user.status);
    const [globalError, setGlobalError] = useState<string | null>(null);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        password: Yup.string()
            .required('Password is required')    
    });

    const initialValues = {
        email: '',
        password: ''
    };

    const handleSubmit = async (values: { email: string; password: string; })=>{
        setGlobalError(null);

        const credentials = {
            username: values.email,
            password: values.password
        };
        
        await dispatch(loginUser(credentials)).unwrap().then((result)=>{}).catch((e) => {
            const message = e?.message || 'Server error';
            setGlobalError(message);
        });
    };

    return (
        <>
            <LoginPageContainer>
                <LoginContainer>
                    <Logo />
                    <h1>Sign in to your account</h1>
                    <StyledSSoContainer>
                        <Button.White fullWidth={true} icon={<Icon.Microsoft />} >Sign in via Microsoft</Button.White>
                    </StyledSSoContainer>
                    <StyledDivider>
                        <Elements.HrText>Or continue with</Elements.HrText>
                    </StyledDivider>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                        >
                        {({handleChange, values, errors, touched, handleSubmit, handleBlur}) => (
                            <StyledFormContainer onSubmit={handleSubmit}>
                                <FormElement.Control fullWidth={true}>
                                    <FormElement.Label>
                                        Email
                                        <FormElement.Input 
                                            name="email" 
                                            type="email"
                                            hasError={!!(touched.email && errors.email)}
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            value={values.email} 
                                        />

                                    <FormElement.ErrorMessage show={!!(touched.email && errors.email)} message={errors.email} />
                                    </FormElement.Label>
                                </FormElement.Control>
                                
                                <FormElement.Control fullWidth={true}>
                                    <FormElement.Label>
                                        Password
                                        <FormElement.Input
                                            name="password"
                                            type="password"
                                            onBlur={handleBlur}
                                            hasError={!!(touched.password && errors.password)}
                                            onChange={handleChange} 
                                            value={values.password} 
                                        />
                                    <FormElement.ErrorMessage show={!!(touched.password && errors.password)} message={errors.password} />

                                    </FormElement.Label>
                                </FormElement.Control>
                                {globalError &&
                                    <FormElement.Control fullWidth={true}>
                                        <Elements.NotificationBlock status='danger'>{globalError}</Elements.NotificationBlock>
                                    </FormElement.Control>
                                }

                                <StyledLoginButtonContainer>
                                    <Button.Primary loading={loginStatus === 'loading'} type="submit" fullWidth={true}>Sign in</Button.Primary>
                                </StyledLoginButtonContainer>

                            </StyledFormContainer>
                        )}
                    </Formik>
                </LoginContainer>
            </LoginPageContainer>
        </>
    );
};

const LoginPageContainer = styled.div`
    display: grid;
    place-items: center;
    min-height: 100vh;
`;

const LoginContainer = styled.div`
    max-width: 410px;
    margin-left: auto;
    margin-right: auto;
    padding: ${({theme})=>theme.spacing[4]} ${({theme})=>theme.spacing[2]};
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    ${LogoContainerStyled}{
        margin-bottom: ${({theme})=>theme.spacing[4]}
    }
    >h1{
        margin-top: 0;
    }
`;

const StyledLoginButtonContainer = styled.div`
    margin-top: ${({theme})=>theme.spacing[2]};
    width: 100%;
`;

const StyledFormContainer = styled.form`
    margin-top: ${({theme})=>theme.spacing[1]};
    width: 100%;
`;

const StyledDivider = styled.div`
    margin: ${({theme})=>theme.spacing[2]} 0;
    width: 100%;
`;

const StyledSSoContainer = styled.div`
    margin: ${({theme})=>theme.spacing[1]} 0;
    width: 100%;
`;

export default LoginPage;
