import { API } from 'aws-amplify';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DeleteEmployeeMutation, DeleteEmployeeMutationVariables } from '../../API';
import { deleteEmployee } from '../../graphql/mutations';

export const deleteEmployeeThunk = createAsyncThunk('currentEmployee/delete', async (id: string) => {
    const variables : DeleteEmployeeMutationVariables = {
        input: {id: id}
    };
    const apiData = (await API.graphql({ query: deleteEmployee, variables: variables})) as { data: DeleteEmployeeMutation };    
    const employee = apiData.data.deleteEmployee;
    return employee;
}
);