import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import datepickerStyle from './datepicker';
import typography from './typography';

const GlobalStyle = createGlobalStyle`
  ${normalize}  
  *{
    box-sizing: border-box;
    
  }

  ${typography}
  ${datepickerStyle}
`;

export default GlobalStyle;