import { Auth } from 'aws-amplify';
import { createAsyncThunk } from '@reduxjs/toolkit';

interface IloginUser {
    username: string,
    password: string
}

interface IUserResponse{
    username: string
}

export const loginUser = createAsyncThunk('user/login', async ({username, password}: IloginUser, { rejectWithValue }) => {
    return Auth.signIn(username, password).then((response)=>{
        const user : IUserResponse = {
            username: response.username
        };
        return user;
    });
  });
