import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../components/shared/Button';
import Elements from '../../../components/shared/Elements';
import Panel from '../../../components/shared/Panel';
import { listEmployeesThunk } from '../../../reducers/employeeList/listEmployees';
import { RootState, useAppDispatch } from '../../../store';
import EmployeesList from './EmployeeList';

const EmployeeListPage = () => {
  const dispatch = useAppDispatch();
  const employees = useSelector((state: RootState) => state.employees.employees);
  const employeesStatus = useSelector(
    (state: RootState) => state.employees.employeesStatus
  );

  useEffect(() => {
    async function getEmployeeList() {
      dispatch(listEmployeesThunk());
    }
    getEmployeeList();
  }, [dispatch]);

  return (
    <Panel.Container fullScreen={true} flexDirection="column">
      <Panel.Header>
        <h1>Employee List</h1>
        {/* <Elements.Link to="new-project">
          <Button.Primary>Add new</Button.Primary>
        </Elements.Link> */}
      </Panel.Header>
      {employeesStatus === 'loading' ? (
        <p>Loading</p>
      ) : (
        <EmployeesList employees={employees} />
      )}
    </Panel.Container>
  );
};

export default EmployeeListPage;
