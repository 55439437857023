
import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import Button from '../components/shared/Button';
import Elements from '../components/shared/Elements';
import { NotificationBlock } from '../components/shared/Elements/NotificationBlock';
import FormElement from '../components/shared/FormElement';
import Panel from '../components/shared/Panel';
import { useModal } from '../components/shared/Modal/useModal';
import Modal from '../components/shared/Modal';
import Icon from '../components/shared/Icon';

const StyledElementContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  > *:first-child {
    margin-left: 0;
  }
  > * {
    margin-left: 2rem;
  }
`;

const StyleGuide = () => {
  const [examplePhone, setExamplePhone] = useState();
  const [exampleCheckbox, setExampleCheckbox] = useState(false);
  const [startDate, setStartDate] = useState<string | undefined>(
    new Date().toISOString()
  );
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) =>
    setExampleCheckbox(event.target.checked);
  const { isShown, toggle } = useModal();

  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];

  const [selectedOption, setSelectedOption] = useState(null);

  return (
    <>
      <Panel.Container flexDirection="column">
        <Panel.Header>
          <h1>Panel header</h1>
        </Panel.Header>
        <Panel.Subheader>Panel Subheader</Panel.Subheader>
        <h2>Second level header</h2>
        <h3>Third level header</h3>
        <h4>Fourth level header</h4>
      </Panel.Container>

      <Panel.Container flexDirection="column">
        <Panel.Header>
          <h1>Buttons</h1>
        </Panel.Header>
        <h3>Primary</h3>
        <StyledElementContainer>
          <Button.Primary size="small">Small button</Button.Primary>
          <Button.Primary>Medium button</Button.Primary>
          <Button.Primary size="large">Large button</Button.Primary>
        </StyledElementContainer>
        <h3>Danger</h3>
        <StyledElementContainer>
          <Button.Danger size="small">Small button</Button.Danger>
          <Button.Danger>Medium button</Button.Danger>
          <Button.Danger size="large">Large button</Button.Danger>
        </StyledElementContainer>
        <h3>Link</h3>
        <StyledElementContainer>
          <Button.Link size="small">Small button</Button.Link>
          <Button.Link>Medium button</Button.Link>
          <Button.Link size="large">Large button</Button.Link>
        </StyledElementContainer>

        <h3>White</h3>

        <StyledElementContainer>
          <Button.White size="small">Small button</Button.White>
          <Button.White>Medium button</Button.White>
          <Button.White size="large">Large button</Button.White>
        </StyledElementContainer>

        <h3>Loading</h3>

        <StyledElementContainer>
          <Button.Primary loading={true}>Loading button</Button.Primary>
          <Button.Danger loading={true}>Loading button</Button.Danger>
          <Button.White loading={true}>Loading button</Button.White>
        </StyledElementContainer>
      </Panel.Container>

      <Panel.Container>
        <Panel.Header>
          <h1>Elements</h1>
        </Panel.Header>
        <h3>Horizontal line</h3>
        <Elements.Hr />
        <h3>Badges</h3>
        <StyledElementContainer>
          <Elements.Badge status="success">Ongoing</Elements.Badge>
          <Elements.Badge status="warning">Paused</Elements.Badge>
          <Elements.Badge status="danger">On hold</Elements.Badge>
        </StyledElementContainer>
        <h3>Spinner</h3>
        <Elements.Spinner />
      </Panel.Container>

      <Panel.Container>
        <Panel.Header>
          <h1>Modals</h1>
        </Panel.Header>

        <Modal.FullScreen isShown={isShown} toggle={toggle}>
          <Modal.Header>
            <Modal.CloseButton onClick={toggle}>
              <Icon.Close />
            </Modal.CloseButton>
            <h3>Positions and staffing</h3>
          </Modal.Header>
          This is modal content
        </Modal.FullScreen>

        <Button.Primary onClick={toggle}>Open modal</Button.Primary>
      </Panel.Container>

      <Panel.Container>
        <Panel.Header>
          <h1>Form</h1>
        </Panel.Header>
        <h4>Basic inputs</h4>
        <FormElement.Control>
          <FormElement.Label>
            Input label
            <FormElement.Input defaultValue={"This is user's input"} />
          </FormElement.Label>
        </FormElement.Control>

        <FormElement.Control>
          <FormElement.Label>
            Input label
            <FormElement.Input placeholder={'This is a placeholder'} />
          </FormElement.Label>
        </FormElement.Control>

        <FormElement.Control>
          <FormElement.Label>
            Invalid field
            <FormElement.Input hasError={true} defaultValue={'Invalid value'} />
            <FormElement.ErrorMessage
              show={true}
              message={'This is error message'}
            ></FormElement.ErrorMessage>
          </FormElement.Label>
        </FormElement.Control>

        <FormElement.Control>
          <FormElement.Label>
            Input label
            <FormElement.TextArea placeholder={'This is a placeholder'} />
          </FormElement.Label>
        </FormElement.Control>

        {/* <FormElement.Control>
                <FormElement.Label>
                    Checkbox
                    <FormElement.Checkbox onChange={handleCheckboxChange} checked={exampleCheckbox} />
                </FormElement.Label>
            </FormElement.Control> */}

        <h4>Phone input</h4>

        <FormElement.Control>
          <FormElement.Phone />
        </FormElement.Control>

        <FormElement.Control>
          <NotificationBlock status="danger">
            This is some global error text
          </NotificationBlock>
        </FormElement.Control>

        <FormElement.Control>
          <FormElement.Label>
            Dropdown select
            <FormElement.Select
              isSearchable={false}
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={options}
            />
          </FormElement.Label>
        </FormElement.Control>

        <FormElement.Control>
          <FormElement.Label>
            {startDate && (
              <>date selected : {format(new Date(startDate), 'MM/dd/yyyy')}</>
            )}
            <FormElement.Datepicker
              onChange={setStartDate}
              startDate={startDate}
            />
          </FormElement.Label>
        </FormElement.Control>
      </Panel.Container>
    </>
  );
};

export default StyleGuide;
