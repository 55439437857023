import styled from 'styled-components';
import IconStyled from './StyledIcon';
import MicrosoftLogo from './../../../assets/icons/microsoft-logo.svg';

const MicrosoftIcon = styled(IconStyled)`
    background-image: url(${MicrosoftLogo});
    background-size: contain;
    background-repeat: no-repeat;
`;

export default MicrosoftIcon;