import StartDateBeforeTodayNotification from '../Notifications/StartDateBeforeTodayNotification';
import { addDays } from 'date-fns';
import { WorkPackageType } from '../../../types/workPackage.type';

interface IWorkPackageNotifications{
    workPackage: WorkPackageType
}

const WorkPackageNotifications = ({workPackage}: IWorkPackageNotifications): JSX.Element=>{
    return(
        <>
            {workPackage.endDate && new Date() > addDays(new Date(workPackage.startDate), 1) &&
                <StartDateBeforeTodayNotification />
            }
        </>
    );
};

export default WorkPackageNotifications;
