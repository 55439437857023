/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      name
      companies {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      name
      companies {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      name
      companies {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      startDate
      endDate
      clientCompaniesId
      __typename
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      startDate
      endDate
      clientCompaniesId
      __typename
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      startDate
      endDate
      clientCompaniesId
      __typename
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      name
      description
      test8
      workPackages {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      name
      description
      test8
      workPackages {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      name
      description
      test8
      workPackages {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRate = /* GraphQL */ `
  mutation CreateRate(
    $input: CreateRateInput!
    $condition: ModelRateConditionInput
  ) {
    createRate(input: $input, condition: $condition) {
      id
      quantity
      value
      currency
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRate = /* GraphQL */ `
  mutation UpdateRate(
    $input: UpdateRateInput!
    $condition: ModelRateConditionInput
  ) {
    updateRate(input: $input, condition: $condition) {
      id
      quantity
      value
      currency
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRate = /* GraphQL */ `
  mutation DeleteRate(
    $input: DeleteRateInput!
    $condition: ModelRateConditionInput
  ) {
    deleteRate(input: $input, condition: $condition) {
      id
      quantity
      value
      currency
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole(
    $input: CreateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    createRole(input: $input, condition: $condition) {
      id
      name
      description
      packages {
        nextToken
        __typename
      }
      defaultRate {
        id
        quantity
        value
        currency
        createdAt
        updatedAt
        __typename
      }
      workPackages {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      roleDefaultRateId
      __typename
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole(
    $input: UpdateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    updateRole(input: $input, condition: $condition) {
      id
      name
      description
      packages {
        nextToken
        __typename
      }
      defaultRate {
        id
        quantity
        value
        currency
        createdAt
        updatedAt
        __typename
      }
      workPackages {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      roleDefaultRateId
      __typename
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole(
    $input: DeleteRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    deleteRole(input: $input, condition: $condition) {
      id
      name
      description
      packages {
        nextToken
        __typename
      }
      defaultRate {
        id
        quantity
        value
        currency
        createdAt
        updatedAt
        __typename
      }
      workPackages {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      roleDefaultRateId
      __typename
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee(
    $input: CreateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    createEmployee(input: $input, condition: $condition) {
      id
      name
      lastName
      description
      email
      phone
      role
      employmentType
      createdAt
      updatedAt
      packages {
        nextToken
        __typename
      }
      workPackages {
        nextToken
        __typename
      }
      defaultRate {
        id
        quantity
        value
        currency
        createdAt
        updatedAt
        __typename
      }
      unavailable {
        nextToken
        __typename
      }
      unavailablePeriods {
        nextToken
        __typename
      }
      employeeDefaultRateId
      __typename
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee(
    $input: UpdateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    updateEmployee(input: $input, condition: $condition) {
      id
      name
      lastName
      description
      email
      phone
      role
      employmentType
      createdAt
      updatedAt
      packages {
        nextToken
        __typename
      }
      workPackages {
        nextToken
        __typename
      }
      defaultRate {
        id
        quantity
        value
        currency
        createdAt
        updatedAt
        __typename
      }
      unavailable {
        nextToken
        __typename
      }
      unavailablePeriods {
        nextToken
        __typename
      }
      employeeDefaultRateId
      __typename
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee(
    $input: DeleteEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    deleteEmployee(input: $input, condition: $condition) {
      id
      name
      lastName
      description
      email
      phone
      role
      employmentType
      createdAt
      updatedAt
      packages {
        nextToken
        __typename
      }
      workPackages {
        nextToken
        __typename
      }
      defaultRate {
        id
        quantity
        value
        currency
        createdAt
        updatedAt
        __typename
      }
      unavailable {
        nextToken
        __typename
      }
      unavailablePeriods {
        nextToken
        __typename
      }
      employeeDefaultRateId
      __typename
    }
  }
`;
export const createUnavailable = /* GraphQL */ `
  mutation CreateUnavailable(
    $input: CreateUnavailableInput!
    $condition: ModelUnavailableConditionInput
  ) {
    createUnavailable(input: $input, condition: $condition) {
      id
      startDate
      endDate
      createdAt
      updatedAt
      employee {
        id
        name
        lastName
        description
        email
        phone
        role
        employmentType
        createdAt
        updatedAt
        employeeDefaultRateId
        __typename
      }
      employeeUnavailableId
      __typename
    }
  }
`;
export const updateUnavailable = /* GraphQL */ `
  mutation UpdateUnavailable(
    $input: UpdateUnavailableInput!
    $condition: ModelUnavailableConditionInput
  ) {
    updateUnavailable(input: $input, condition: $condition) {
      id
      startDate
      endDate
      createdAt
      updatedAt
      employee {
        id
        name
        lastName
        description
        email
        phone
        role
        employmentType
        createdAt
        updatedAt
        employeeDefaultRateId
        __typename
      }
      employeeUnavailableId
      __typename
    }
  }
`;
export const deleteUnavailable = /* GraphQL */ `
  mutation DeleteUnavailable(
    $input: DeleteUnavailableInput!
    $condition: ModelUnavailableConditionInput
  ) {
    deleteUnavailable(input: $input, condition: $condition) {
      id
      startDate
      endDate
      createdAt
      updatedAt
      employee {
        id
        name
        lastName
        description
        email
        phone
        role
        employmentType
        createdAt
        updatedAt
        employeeDefaultRateId
        __typename
      }
      employeeUnavailableId
      __typename
    }
  }
`;
export const createUnavailablePeriod = /* GraphQL */ `
  mutation CreateUnavailablePeriod(
    $input: CreateUnavailablePeriodInput!
    $condition: ModelUnavailablePeriodConditionInput
  ) {
    createUnavailablePeriod(input: $input, condition: $condition) {
      id
      startDate
      endDate
      createdAt
      updatedAt
      employeeId
      employeeUnavailablePeriodsId
      __typename
    }
  }
`;
export const updateUnavailablePeriod = /* GraphQL */ `
  mutation UpdateUnavailablePeriod(
    $input: UpdateUnavailablePeriodInput!
    $condition: ModelUnavailablePeriodConditionInput
  ) {
    updateUnavailablePeriod(input: $input, condition: $condition) {
      id
      startDate
      endDate
      createdAt
      updatedAt
      employeeId
      employeeUnavailablePeriodsId
      __typename
    }
  }
`;
export const deleteUnavailablePeriod = /* GraphQL */ `
  mutation DeleteUnavailablePeriod(
    $input: DeleteUnavailablePeriodInput!
    $condition: ModelUnavailablePeriodConditionInput
  ) {
    deleteUnavailablePeriod(input: $input, condition: $condition) {
      id
      startDate
      endDate
      createdAt
      updatedAt
      employeeId
      employeeUnavailablePeriodsId
      __typename
    }
  }
`;
export const createWorkPackage = /* GraphQL */ `
  mutation CreateWorkPackage(
    $input: CreateWorkPackageInput!
    $condition: ModelWorkPackageConditionInput
  ) {
    createWorkPackage(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      startDate
      endDate
      projectWorkPackagesId
      roles {
        nextToken
        __typename
      }
      employees {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateWorkPackage = /* GraphQL */ `
  mutation UpdateWorkPackage(
    $input: UpdateWorkPackageInput!
    $condition: ModelWorkPackageConditionInput
  ) {
    updateWorkPackage(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      startDate
      endDate
      projectWorkPackagesId
      roles {
        nextToken
        __typename
      }
      employees {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteWorkPackage = /* GraphQL */ `
  mutation DeleteWorkPackage(
    $input: DeleteWorkPackageInput!
    $condition: ModelWorkPackageConditionInput
  ) {
    deleteWorkPackage(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      startDate
      endDate
      projectWorkPackagesId
      roles {
        nextToken
        __typename
      }
      employees {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createWorkPackageRole = /* GraphQL */ `
  mutation CreateWorkPackageRole(
    $input: CreateWorkPackageRoleInput!
    $condition: ModelWorkPackageRoleConditionInput
  ) {
    createWorkPackageRole(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      capacity
      role {
        id
        name
        description
        createdAt
        updatedAt
        roleDefaultRateId
        __typename
      }
      employee {
        id
        name
        lastName
        description
        email
        phone
        role
        employmentType
        createdAt
        updatedAt
        employeeDefaultRateId
        __typename
      }
      workPackageRolesId
      rolePackagesId
      employeePackagesId
      __typename
    }
  }
`;
export const updateWorkPackageRole = /* GraphQL */ `
  mutation UpdateWorkPackageRole(
    $input: UpdateWorkPackageRoleInput!
    $condition: ModelWorkPackageRoleConditionInput
  ) {
    updateWorkPackageRole(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      capacity
      role {
        id
        name
        description
        createdAt
        updatedAt
        roleDefaultRateId
        __typename
      }
      employee {
        id
        name
        lastName
        description
        email
        phone
        role
        employmentType
        createdAt
        updatedAt
        employeeDefaultRateId
        __typename
      }
      workPackageRolesId
      rolePackagesId
      employeePackagesId
      __typename
    }
  }
`;
export const deleteWorkPackageRole = /* GraphQL */ `
  mutation DeleteWorkPackageRole(
    $input: DeleteWorkPackageRoleInput!
    $condition: ModelWorkPackageRoleConditionInput
  ) {
    deleteWorkPackageRole(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      capacity
      role {
        id
        name
        description
        createdAt
        updatedAt
        roleDefaultRateId
        __typename
      }
      employee {
        id
        name
        lastName
        description
        email
        phone
        role
        employmentType
        createdAt
        updatedAt
        employeeDefaultRateId
        __typename
      }
      workPackageRolesId
      rolePackagesId
      employeePackagesId
      __typename
    }
  }
`;
export const createWorkPackageEmployee = /* GraphQL */ `
  mutation CreateWorkPackageEmployee(
    $input: CreateWorkPackageEmployeeInput!
    $condition: ModelWorkPackageEmployeeConditionInput
  ) {
    createWorkPackageEmployee(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      capacity
      employee {
        id
        name
        lastName
        description
        email
        phone
        role
        employmentType
        createdAt
        updatedAt
        employeeDefaultRateId
        __typename
      }
      workPackageEmployeesId
      roleWorkPackagesId
      employeeWorkPackagesId
      __typename
    }
  }
`;
export const updateWorkPackageEmployee = /* GraphQL */ `
  mutation UpdateWorkPackageEmployee(
    $input: UpdateWorkPackageEmployeeInput!
    $condition: ModelWorkPackageEmployeeConditionInput
  ) {
    updateWorkPackageEmployee(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      capacity
      employee {
        id
        name
        lastName
        description
        email
        phone
        role
        employmentType
        createdAt
        updatedAt
        employeeDefaultRateId
        __typename
      }
      workPackageEmployeesId
      roleWorkPackagesId
      employeeWorkPackagesId
      __typename
    }
  }
`;
export const deleteWorkPackageEmployee = /* GraphQL */ `
  mutation DeleteWorkPackageEmployee(
    $input: DeleteWorkPackageEmployeeInput!
    $condition: ModelWorkPackageEmployeeConditionInput
  ) {
    deleteWorkPackageEmployee(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      capacity
      employee {
        id
        name
        lastName
        description
        email
        phone
        role
        employmentType
        createdAt
        updatedAt
        employeeDefaultRateId
        __typename
      }
      workPackageEmployeesId
      roleWorkPackagesId
      employeeWorkPackagesId
      __typename
    }
  }
`;
