import { FC, useEffect, useState } from 'react';
import Button from '../../../components/shared/Button';
import Icon from '../../../components/shared/Icon';
import Modal from '../../../components/shared/Modal';
import { IModalProps } from '../../../components/shared/Modal/ModalContainer';
import WorkPackageContainer from './WorkPackageContainer';
import { createEmptyPackage } from '../workPackageHelper';
import { StyledAddAnotherContainer } from './workPackage.styled';
import { RootState, useAppDispatch } from '../../../store';
import { useSelector } from 'react-redux';
import IAWSErrorResponse from '../../../types/AWSError';
import { listDefaultRolesThunk } from '../../../reducers/currentProject/listDefaultRoles';
import { addWorkPackage } from '../../../reducers/currentProjectReducer';
import PackageTotals from './Totals/PackageTotals';

const AddWorkPackageModal: FC<IModalProps> = ({isShown, toggle})=>{
    const project = useSelector((state: RootState) => state.currentProject.currentProject);
    const dispatch = useAppDispatch();
    
    useEffect(()=>{
        async function getDefaultRoles() {
            dispatch(listDefaultRolesThunk());    
        };
        getDefaultRoles();
    },[dispatch]);

    // TODO show error to user
    const [globalError, setGlobalError] = useState<IAWSErrorResponse | null>(null);

    const focusLastPackage = ()=>{
        setTimeout(()=>{
            const packageNameInputs = document.getElementsByClassName('packageNameInput') as HTMLCollectionOf<HTMLElement>;
            const lastElement = packageNameInputs[packageNameInputs.length - 1];
            if (lastElement){
                lastElement.focus();
            }
        }, 100);
    };

    return(
        <Modal.FullScreen isShown={isShown} toggle={toggle}> 
            <>
                <Modal.Header>
                    <Modal.CloseButton onClick={toggle}>
                        <Icon.Close  />
                    </Modal.CloseButton>
                    <h3>Work packages and staffing</h3>
                    <PackageTotals />
                </Modal.Header>

                <Modal.Body>
                    <>
                        {project?.workPackages?.items?.map((workPackage, index)=>
                            <WorkPackageContainer key={workPackage.id} workPackage={workPackage} index={index} />
                        )}
                        <StyledAddAnotherContainer>
                            <Button.Primary type='button' onClick={()=>{
                                dispatch(addWorkPackage(createEmptyPackage()));
                                focusLastPackage();
                            }}>Add package</Button.Primary>
                        </StyledAddAnotherContainer>

                    </>
                </Modal.Body>
            </>
        </Modal.FullScreen>
    );
};

export default AddWorkPackageModal;