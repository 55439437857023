import { useMemo } from 'react';
import { WorkPackageRoleType, WorkPackageType } from '../../../types/workPackage.type';
import { calculateWorkingDays } from '../workPackageHelper';
import { StyledDescription } from './workPackage.styled';

interface IRoleDetails{
    workPackage: WorkPackageType,
    role: WorkPackageRoleType
};

const RoleDetails = ({workPackage, role}: IRoleDetails): JSX.Element=>{

    const workingDays = useMemo(()=>{
        if (!workPackage.startDate || !workPackage.endDate || !role.capacity){
            return null;
        }
        return calculateWorkingDays(
            new Date(workPackage.startDate), 
            new Date(workPackage.endDate),
            role.capacity);
    }, [workPackage.startDate, workPackage.endDate, role.capacity]);

    return (
        <StyledDescription>
            {workingDays} days
        </StyledDescription>
    );
};

export default RoleDetails;