import { CountryCode, E164Number } from 'libphonenumber-js/types';
import React, { useEffect, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/input';
import styled from 'styled-components';
import FormElement from '.';
import Input from './Input';

interface ICountryCode{
    label: string,
    value: CountryCode
}

const StyledPhoneContainer = styled.div`
    display: flex;
    > input{
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
`;

const Phone = ()=>{
    const [countryCode, setCountryCode] = useState<ICountryCode>();
    const [phoneValue, setPhoneValue] = useState<E164Number>();
    const [isValid, setIsValid] = useState(true);

    useEffect(()=>{
        setIsValid(phoneValue ? isValidPhoneNumber(phoneValue.toString(), countryCode?.value) : false);
    }, [phoneValue, countryCode]);


    return (
        <StyledPhoneContainer>
            <FormElement.CountrySelect hasError={phoneValue && !isValid} defaultCountryCode="LT" value={countryCode} onChange={setCountryCode} />
            <PhoneInput
                inputComponent={Input}
                hasError={phoneValue && !isValid}
                country={countryCode?.value}
                international={false}
                placeholder="Enter phone number"
                value={phoneValue}
                onChange={setPhoneValue}/>
        </StyledPhoneContainer>
    );
};

export default Phone;