import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../components/shared/Button';
import { RootState } from '../../../store';
import { WorkPackageType } from '../../../types/workPackage.type';
import { addWorkPackageRoleAPI, createEmptyRole } from '../workPackageHelper';

interface IAddRoleContainer{
    workPackage: WorkPackageType
}

const AddRoleContainer = ({workPackage}: IAddRoleContainer): JSX.Element=>{

    const addRole = ()=>{
        if (buttonDisabled) return;
        let newRole = createEmptyRole(defaultRoles, workPackage.id);
        addWorkPackageRoleAPI(newRole);
    };

    const buttonDisabled = useMemo(()=>{
        return !workPackage.endDate || !workPackage.startDate || !workPackage.name;
    }, [workPackage.endDate, workPackage.startDate, workPackage.name]);

    const defaultRoles = useSelector((state: RootState) => state.currentProject.defaultRoles);

    return (
        <StyledContainer>
        {defaultRoles?.length &&
            <Button.White disabled={buttonDisabled} type='button' onClick={addRole} fullWidth={true}>Add role</Button.White>
        }
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    border-top: 1px solid ${({theme})=>theme.elements.line};
    padding: ${({theme})=>theme.spacing[2]} ${({theme})=>theme.spacing[3]} ${({theme})=>theme.spacing[1]};
`;


export default AddRoleContainer;