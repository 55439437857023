import styled from 'styled-components';

const PanelSubheader = styled.div`
    width: 100%;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.2em;
    color: ${({theme})=>theme.text.secondary};
`;

export default PanelSubheader;