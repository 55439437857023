import styled from 'styled-components';
import IconStyled from './StyledIcon';
import CloseImage from './../../../assets/icons/close.svg';

const CloseIcon = styled(IconStyled)`
    background-image: url(${CloseImage});
    background-size: contain;
    background-repeat: no-repeat;
`;

export default CloseIcon;