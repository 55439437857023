import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Panel from '../../../components/shared/Panel';
import { deleteEmployeeThunk } from '../../../reducers/currentEmployee/deleteEmployee';
import { getEmployeeThunk } from '../../../reducers/currentEmployee/getEmployee';
import { RootState, useAppDispatch } from '../../../store';
import EmployeeContainer from './EmployeeContainer';
import { currentEmployeeType } from '../../../types/employee.type';


const EmployeePage = () => {
  let { id } = useParams();
  const employee = useSelector(
    (state: RootState) => state.currentEmployee.currentEmployee
  );
  const employeeStatus = useSelector(
    (state: RootState) => state.currentEmployee.employeeStatus
  );

  const dispatch = useAppDispatch();

  let navigate = useNavigate();

  const deleteEmployee = () => {
    if (id) {
      dispatch(deleteEmployeeThunk(id))
        .unwrap()
        .then(() => {
          navigate('/employees');
        });
    }
  };
  useEffect(() => {
    async function getEmployeeById(id: string) {
      if (mounted) {
        dispatch(getEmployeeThunk(id));
      }
    }
    let mounted = true;
    if (id) {
        getEmployeeById(id);
    }
    return () => {
      mounted = false;
    }; // cleanup function
  }, [id, dispatch]);


  console.log(employee);

  return (
    <>
      {employee && employeeStatus !== 'loading' && (
        <EmployeeContainer deleteEmployee={deleteEmployee} employee={employee} index={0} />
      )}
      {!employee && employeeStatus !== 'loading' && (
        <Panel.Container>
          <p>Employee not found</p>
        </Panel.Container>
      )}
      {employeeStatus === 'loading' && (
        <Panel.Container>
          <p>Loading...</p>
        </Panel.Container>
      )}
    </>
  );
};

export default EmployeePage;
