import styled from 'styled-components';

const ThStyled = styled.th`
  border: none;
  padding: 16px 16px;
  color: ${({ theme }) => theme.text.secondary};
  letter-spacing: 1px;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
  background: #fcfcfc;
`;

export default ThStyled;
