import Input from './Input';
import Label from './Label';
import Control from './Control';
import CountrySelect from './CountrySelect';
import Phone from './Phone';
import Checkbox from './Checkbox';
import ErrorMessage from './ErrorMessage';
import Datepicker from './Datepicker';
import Select from './Select';
import TextArea from './TextArea';
import AWSErrorMessage from './AWSErrorMessage';
import Horizontal from './Horizontal';

const FormElement = {
    Input,
    Label,
    Control,
    Select,
    CountrySelect,
    ErrorMessage,
    Horizontal,
    Phone,
    Checkbox,
    Datepicker,
    TextArea,
    AWSErrorMessage
};

export default FormElement;