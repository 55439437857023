import { API } from 'aws-amplify';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateWorkPackageRoleMutation, CreateWorkPackageRoleMutationVariables } from '../../API';
import { createWorkPackageRole } from '../../graphql/mutations';
import { WorkPackageRoleType } from '../../types/workPackage.type';

export const addPackageRoleThunk = createAsyncThunk('currentProject/addPackageRole', 
    async (workPackageRole : WorkPackageRoleType) => {
    const variables : CreateWorkPackageRoleMutationVariables = {
        input: {
            rolePackagesId: workPackageRole.rolePackagesId,
            capacity: workPackageRole.capacity,
            workPackageRolesId: workPackageRole.workPackageRolesId || '',
        }
    };
    const apiData = (await API.graphql({ query: createWorkPackageRole, variables: variables})) as { data: CreateWorkPackageRoleMutation };    
    const response = apiData.data.createWorkPackageRole;
    return response;
});
