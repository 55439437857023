import { createSlice } from '@reduxjs/toolkit';
import loadingState from '../types/loadingState.type';
import { loginUser } from './user/loginUser';
import { logoutUser } from './user/logoutUser';

export interface UserState {
  loggedIn: boolean,
  status: loadingState,
  username?: string
}

const initialState: UserState = {
    loggedIn: false,
    status: 'loading',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
        state.loggedIn = false;
    },
    refreshLogin: (state, action) => {
      state.loggedIn = action.payload.loggedIn;
      state.status = state.loggedIn ? 'succeeded' : 'idle';
      if (state.loggedIn){
        state.username = action.payload.username;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginUser.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        if (action.payload){
          state.loggedIn = true;
          state.status = 'succeeded';
          state.username = action?.payload?.username;  
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.loggedIn = false;
        state.status = 'idle';
      });

  }
});

// Action creators are generated for each case reducer function
export const { logout, refreshLogin } = userSlice.actions;

export default userSlice.reducer;