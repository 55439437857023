import styled from 'styled-components';
import IconStyled from './StyledIcon';
import XImage from './../../../assets/icons/x.svg';

const XIcon = styled(IconStyled)`
    mask: url(${XImage});
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: ${({theme})=>theme.palette.gray50};
    &:hover{
        background-color: ${({theme})=>theme.palette.gray10};
    }
`;

export default XIcon;