import styled from 'styled-components';
import Button from './buttonStyling';

const LinkButton = styled(Button)`
    background-color: transparent;
    color: ${({theme})=>theme.palette.primary};
    &:focus{
        outline: 3px solid ${({theme})=>theme.palette.primary};
        background-color: transparent;
    }
    &:hover{
        text-decoration: underline;
    }
`;

export default LinkButton;