import { FC } from 'react';
import styled from 'styled-components';
import Hr from './Hr';

const StatusContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  > ${Hr} {
    flex-grow: 1;
  }
`;

const Status: FC = ({ children }) => (
  <StatusContainer>
    <span>{children}</span>
  </StatusContainer>
);

export default Status;
