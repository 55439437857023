import { API } from 'aws-amplify';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DeleteProjectMutation, DeleteProjectMutationVariables } from '../../API';
import { deleteProject } from '../../graphql/mutations';

export const deleteProjectThunk = createAsyncThunk('currentProject/delete', async (id: string) => {
    const variables : DeleteProjectMutationVariables = {
        input: {id: id}
    };
    const apiData = (await API.graphql({ query: deleteProject, variables: variables})) as { data: DeleteProjectMutation };    
    const project = apiData.data.deleteProject;
    return project;
});
