import styled from 'styled-components';
import Icon from '../Icon';

const Header = styled.div`
    display: flex;
    align-items: center;
    padding: ${({theme})=>theme.spacing[1]} ${({theme})=>theme.spacing[4]};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

    ${Icon.Close}{
        cursor: pointer;
    }

    ${Icon.Close} + *{
        margin-left: ${({theme})=>theme.spacing[2]};
    }
`;

export default Header;