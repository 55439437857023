import React, { useEffect } from 'react';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import Select from 'react-select';
import styled from 'styled-components';
import { lighten } from 'polished';

interface ICountrySelect{
  onChange: any,
  value: any,
  hasError? : boolean,
  defaultCountryCode?: string
};

interface IStyledCountrySelect {
  hasError? : boolean,
}

const StyledCountrySelect = styled(Select)<IStyledCountrySelect>`
  .Select__control{
    min-width: 85px;
    font-size: 1.6rem;
    border-radius: 2px;
    border-color: ${({theme, hasError})=>hasError ? lighten(0.1, theme.palette.danger) :theme.palette.gray};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .Select__single-value{
    color: ${({theme, hasError})=>hasError ? theme.palette.danger : theme.text.default};
  }

  .Select__control--is-focused{
    box-shadow: none;
  }
  .Select__indicator-separator{
    display: none;
  }
  .Select__indicator{
    padding-left: 0px;
    /* padding-right: 0px; */
  }
  .Select__input-container{
    padding: 0;
    line-height: 1;
  }
  .Select__value-container{
    padding: 0.5em 0.4em;
  }

`;

const uniqueCountries: string[] = [];

const countryList = getCountries().filter((country)=>{
  const countryCode = getCountryCallingCode(country);
  if (!uniqueCountries.includes(countryCode)){
    uniqueCountries.push(countryCode);
    return true;
  }
  return false;
}).map(countryCode=>(
  {label: '+'+getCountryCallingCode(countryCode), value: countryCode}
));

const CountrySelect = ({ value, onChange, defaultCountryCode, hasError }: ICountrySelect) =>{
  useEffect(()=>{
    if(defaultCountryCode){
      const newValue = countryList.find((country)=>{
        return country.value === defaultCountryCode;
      });
      if (newValue){
        onChange(newValue);
      }
    }
  }, [defaultCountryCode]);

  return (
    <StyledCountrySelect
          value={value}
          hasError={hasError}
          classNamePrefix={'Select'}
          onChange={onChange}
          options={countryList}
        />
  );
};

export default CountrySelect;