import Container from './TableContainer';
import Th from './TableTh';
import Td from './TableTd';
import Tr from './TableTr';
import Wrapper from './TableWrapper';

const Table = {
  Wrapper,
  Container,
  Th,
  Tr,
  Td,
};

export default Table;
