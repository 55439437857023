import styled from 'styled-components';
import Control from './Control';

const Horizontal = styled.div`
    display: flex;
    flex-wrap: wrap;
    ${Control}{
        margin-top: ${({theme})=>theme.spacing[1]};
    }
    >*:not(:last-child){
        margin-right: ${({theme})=>theme.spacing[2]};
    }
`;

export default Horizontal;