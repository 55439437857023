import { defaultRoleType } from "./types/defaultRole.type";
import { currentProjectType } from "./types/project.type";
import { currentEmployeeType } from "./types/employee.type";

export const getProjectExtended = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      description
      test8
      workPackages {
        items {
          id
          name
          startDate
          endDate
          projectWorkPackagesId
          roles {
            items {
              id
              capacity
              workPackageRolesId
              rolePackagesId
              role {
                name
                id
                defaultRate {
                  value
                }
              }
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export type getProjectQueryExtended = {
  getProject?: currentProjectType;
};

export type ListProjectsQueryExtended = {
  listProjects?: {
    items: Array<{
      id: string;
      name: string;
      description?: string | null;
      createdAt: string;
    }>;
    nextToken?: string | null;
  };
};

export const listProjectsExtended = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
      }
      nextToken
    }
  }
`;

export type ListDefaultRolesQueryExtended = {
  listRoles?: {
    items: Array<defaultRoleType>;
    nextToken?: string | null;
  };
};

export const listDefaultRolesExtended = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        defaultRate {
          value
        }
      }
    }
  }
`;

export const listEmployeesExtended = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
      }
      nextToken
    }
  }
`;

export type ListEmployeesQueryExtended = {
  listEmployees?: {
    items: Array<{
      id: string;
      name: string;
      description?: string | null;
      createdAt: string;
    }>;
    nextToken?: string | null;
  };
};

export const getEmployeeExtended = /* GraphQL */ `
  query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      id
      name
      description
      email
      phone
      role
      createdAt
      updatedAt
      unavailablePeriods {
        items {
          id
          startDate
          endDate
          employeeId
        }
        nextToken
      }
    }
  }
`;

export type getEmployeeQueryExtended = {
  getEmployee?: currentEmployeeType;
};
