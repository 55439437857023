  import styled, { keyframes } from 'styled-components';

const Spinner = ()=>{
    return (
        <SpinnerContainer>
            <SpinnerRing />
            <SpinnerRing />
            <SpinnerRing />
        </SpinnerContainer>
    );
};

export default Spinner;

const SpinnerContainer = styled.div`
    display: inline-block;
    position: relative;
    width: 45px;
    height: 45px;
    > *{
        &:nth-child(1) {
            animation-delay: -0.45s;
        }
        &:nth-child(2) {
            animation-delay: -0.3s;
        }
        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
`;

const spinAnimation = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

const SpinnerRing = styled.div`
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 45px;
    height: 45px;
    margin: 6px;
    border: 6px solid ${({theme})=>theme.elements.line};
    border-radius: 50%;
    animation-name: ${spinAnimation};
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
    border-color: ${({theme})=>theme.elements.line} transparent transparent transparent;
`;