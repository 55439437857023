import { API } from 'aws-amplify';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DeleteWorkPackageRoleMutation, DeleteWorkPackageRoleMutationVariables } from '../../API';
import { deleteWorkPackageRole } from '../../graphql/mutations';

export const deletePackageRoleThunk = createAsyncThunk('currentProject/deletePackageRole', async (id: string) => {
    const variables : DeleteWorkPackageRoleMutationVariables = {
        input: {id: id}
    };
    const apiData = (await API.graphql({ query: deleteWorkPackageRole, variables: variables})) as { data: DeleteWorkPackageRoleMutation };    
    const project = apiData.data.deleteWorkPackageRole;
    return project;
});
