import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { WorkPackageType } from '../../../../types/workPackage.type';
import { RolesTotalsRoleType } from './PackageSummary';
import { StyledSummaryBlock } from './packageSummary.styled';

interface IRoleSummary{
    workPackage: WorkPackageType,
    role: RolesTotalsRoleType
}

const RoleSummary = ({ role } : IRoleSummary ) : JSX.Element =>{

    const defaultRoles = useSelector((state: RootState) => state.currentProject.defaultRoles);

    const roleName = useMemo(()=>{
        if (!defaultRoles?.length){ return;}
        const selectedRole = defaultRoles.find(defaultRole=>(defaultRole.id === role.role));
        return selectedRole?.name || null;
    }, [defaultRoles, role.role]);

    return (
        <StyledSummaryBlock>{roleName} <span> {role.days} d.</span></StyledSummaryBlock>
    );
};


export default RoleSummary;