import React, { FC } from 'react';
import styled from 'styled-components';
import { Tab } from './tab.type';
import TabLink from './TabLink';

interface ITabContainer {
  tabs: Tab[];
}

const TabContainerStyled = styled.div`
  display: flex;
`;

const TabContainer: FC<ITabContainer> = ({ tabs }) => {
  return (
    <TabContainerStyled>
      {tabs.map((tab) => (
        <TabLink key={tab.id} {...tab} />
      ))}
    </TabContainerStyled>
  );
};

export default TabContainer;
