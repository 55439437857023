import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Panel from '../../components/shared/Panel';
import { deleteProjectThunk } from '../../reducers/currentProject/deleteProject';
import { getProjectThunk } from '../../reducers/currentProject/getProject';
import { RootState, useAppDispatch } from '../../store';
import ProjectContainer from './ProjectContainer';

const ProjectPage = () => {
  let { id } = useParams();
  const project = useSelector(
    (state: RootState) => state.currentProject.currentProject
  );
  const projectStatus = useSelector(
    (state: RootState) => state.currentProject.projectStatus
  );

  const dispatch = useAppDispatch();

  let navigate = useNavigate();

  const deleteProject = () => {
    if (id) {
      dispatch(deleteProjectThunk(id))
        .unwrap()
        .then(() => {
          navigate('/projects');
        });
    }
  };

  useEffect(() => {
    async function getProjectById(id: string) {
      if (mounted) {
        dispatch(getProjectThunk(id));
      }
    }
    let mounted = true;
    if (id) {
      getProjectById(id);
    }
    return () => {
      mounted = false;
    }; // cleanup function
  }, [id, dispatch]);

  console.log(project);

  return (
    <>
      {project && projectStatus !== 'loading' && (
        <ProjectContainer deleteProject={deleteProject} project={project} />
      )}
      {!project && projectStatus !== 'loading' && (
        <Panel.Container>
          <p>Project not found</p>
        </Panel.Container>
      )}
      {projectStatus === 'loading' && (
        <Panel.Container>
          <p>Loading</p>
        </Panel.Container>
      )}
    </>
  );
};

export default ProjectPage;
