import { lighten } from 'polished';
import styled from 'styled-components';

interface IInput{
    hasError?: boolean,
    resize?: boolean
}

const TextArea = styled.textarea<IInput>`
    padding: 0.5em 0.7em;
    width: 100%;
    border: 1px solid ${({theme, hasError})=>hasError ? lighten(0.1, theme.palette.danger) : theme.palette.gray};
    border-radius: 2px;
    resize: ${({resize = false})=>resize ? 'vertical': 'none'};
    color: ${({theme, hasError})=>hasError ? theme.palette.danger : theme.text.default};
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.4;
    height: calc(1.4em*6);
    outline: none;
    &:focus{
        outline: 1px solid ${({theme})=>theme.palette.primary};
        outline-offset: -1px;
        color: ${({theme})=>theme.text.default};
    }
`;

export default TextArea;