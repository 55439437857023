import { FC, useState } from 'react';
import styled from 'styled-components';
import Panel from '../../components/shared/Panel';
import { currentProjectType } from '../../types/project.type';
import WorkPackageSection2 from './WorkPackages/WorkPackagesSection2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import BudgetTab from './Tabs/BudgetTab';
import OrdersTab from './Tabs/OrdersTab';
import InvoicesTab from './Tabs/InvoicesTab';
import Button from '../../components/shared/Button';

interface IProjectContainer {
  project: currentProjectType;
  deleteProject: () => void;
}

const ProjectContainer: FC<IProjectContainer> = ({
  project,
  deleteProject,
}) => {
  console.log(project);

  const [activeTab, setActiveTab] = useState('workPackages');

  return (
    <>
      <Panel.Container fullScreen={true} flexDirection="column">
        <ProjectWrapper>
          <StyledAccordion defaultExpanded={true} TransitionProps={{ unmountOnExit: true}}>
            <StyledAccordionSummary
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ProjectInfo>
                <h1>{project.name}</h1>
                <p>PAG-231231</p>
              </ProjectInfo>
              {/* <div>
                <Button.Danger onClick={deleteProject}>
                  Delete project
                </Button.Danger>
              </div> */}
            </StyledAccordionSummary>
            <AccordionDetails>
              <Attributes>
                <AttributeBlock>
                  <Attribute>
                    <h6>Company</h6>
                    <p>Dr.-Ing. h.c. F. Porsche AG</p>
                  </Attribute>
                  <Attribute>
                    <h6>Contact</h6>
                    <p>Matthias Müller</p>
                  </Attribute>
                  <Attribute>
                    <h6>Duration</h6>
                    <p>Apr 1, 2022 - Dec 31, 2023</p>
                  </Attribute>
                </AttributeBlock>
                <AttributeBlock>
                  <Attribute>
                    <h6>Order no</h6>
                    <p>Add</p>
                  </Attribute>
                  <Attribute>
                    <h6>Status</h6>
                    <p>none</p>
                  </Attribute>
                  <Attribute>
                    <h6>Probability</h6>
                    <p>80%</p>
                  </Attribute>
                </AttributeBlock>
              </Attributes>
              <AttributeBlock>
                <Attribute>
                  <h6>Description</h6>
                  <p>{project.description}</p>
                </Attribute>
              </AttributeBlock>
            </AccordionDetails>
          </StyledAccordion>
        </ProjectWrapper>
        <ProjectTabs>
          <TabButton
            className={activeTab === 'workPackages' ? 'active' : ''}
            onClick={() => setActiveTab('workPackages')}
          >
            Work Packages
          </TabButton>
          <TabButton
            className={activeTab === 'budgetOverview' ? 'active' : ''}
            onClick={() => setActiveTab('budgetOverview')}
          >
            Budget Overview
          </TabButton>
          <TabButton
            className={activeTab === 'orders' ? 'active' : ''}
            onClick={() => setActiveTab('orders')}
          >
            Orders
          </TabButton>
          <TabButton
            className={activeTab === 'invoices' ? 'active' : ''}
            onClick={() => setActiveTab('invoices')}
          >
            Invoices
          </TabButton>
        </ProjectTabs>
        <TabContent className={activeTab === 'workPackages' ? 'active' : ''}>
          {/* Work Packages content */}
        </TabContent>
        <TabContent className={activeTab === 'budgetOverview' ? 'active' : ''}>
          {/* Budget Overview content */}
        </TabContent>
        <TabContent className={activeTab === 'orders' ? 'active' : ''}>
          {/* Orders content */}
        </TabContent>
        <TabContent className={activeTab === 'invoices' ? 'active' : ''}>
          {/* Invoices content */}
        </TabContent>
        <TabContent>
          {activeTab === 'workPackages' && (
            <WorkPackages>
              <WorkPackageSection2 workPackages={project.workPackages?.items} />
              {/* {project.workPackages?.items?.length !== 0 ? (
                project.workPackages?.items?.map((workPackage) => (
                  <WorkPackage key={workPackage.id}>
                    <div>
                      <h6>{workPackage.name}</h6>
                      <p>
                        {workPackage.startDate && workPackage.endDate
                          ? `${workPackage.startDate} - ${workPackage.endDate}`
                          : 'No dates'}
                      </p>
                    </div>
                    <div>
                      <p>{workPackage.id}</p>
                    </div>
                  </WorkPackage>
                ))
              ) : (
                <p>No work packages available</p>
              )} */}
            </WorkPackages>
          )}

          {activeTab === 'budgetOverview' && <BudgetTab />}

          {activeTab === 'orders' && <OrdersTab />}

          {activeTab === 'invoices' && <InvoicesTab />}
        </TabContent>
      </Panel.Container>
    </>
  );
};
const Attributes = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const AttributeBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Attribute = styled.div`
  display: flex;
  padding: 4px 0;
  align-items: center;

  h6 {
    font-size: 1.4rem;
    color: #808080;
    font-weight: 400;
    width: 180px;
    margin: 0;
  }
  p {
    font-size: 1.4rem;
    font-weight: 500;
    margin: 0;
  }
`;

const ProjectTabs = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #e6e8ec;
`;

const TabButton = styled.button`
  padding: 14px 0rem;
  margin-right: 5rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  color: #9d9d9d;
  background: none;
  border: none;
  cursor: pointer;
  transition: 0.2s all;

  &:hover {
    background-color: none;
    color: #1d2226;
    transition: 0.2s all;
  }
  &.active {
    color: #1d2226;
    background: none;
  }
`;

const TabContent = styled.div`
  display: initial;
  flex-direction: column;
  &.active {
    display: flex;
  }
`;

const ProjectInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  h1 {
    font-size: 3.2rem;
    margin: 0;
  }
  p {
    margin: 0;
  }
`;

const ProjectWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing[4]} 0
    ${({ theme }) => theme.spacing[4]};
  .MuiAccordionDetails-root {
    padding: 0;
  }
  .MuiAccordion-root {
    box-shadow: none;
    .MuiAccordionSummary-root {
      padding: 0;
    }
  }
`;

const WorkPackages = styled.div`
  position: relative;
  width: 100%;
`;

// const WorkPackage = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: 100%;
// `;
const StyledAccordionSummary = styled(AccordionSummary)`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
  }
  .MuiAccordionSummary-expandIconWrapper {
    background: #d8d8d8;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;

    svg {
      height: 24px;
      width: initial;
      color: #fff;
    }
  }
`;

const StyledAccordion = styled(Accordion)`
  margin-bottom: 1rem;
`;

export default ProjectContainer;
