import styled from 'styled-components';
import IconStyled from './StyledIcon';
import FolderImage from './../../../assets/icons/folder.svg';

const FolderIcon = styled(IconStyled)`
    background-image: url(${FolderImage});
    background-size: contain;
    background-repeat: no-repeat;
`;

export default FolderIcon;