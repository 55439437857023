import { DetailedHTMLProps, forwardRef, InputHTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import FormElement from '.';
import styled from 'styled-components';
import Button from '../Button';
import { lighten } from 'polished';

interface IDatepicker{
    startDate?: string,
    hasError?: boolean,
    onChange: (date?: string) => void
    onBlur?: any,
    name?: string,
};

const options = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },
];

const currentYear = format(new Date(), 'yyyy');


const ReactDatePickerInput = forwardRef<
    HTMLInputElement,
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
    >>
((props, ref) => <input ref={ref} {...props} />);


const DatepickerComponent = ({startDate, onChange, onBlur, hasError, name}: IDatepicker): JSX.Element=>{

    const datePickerRef = useRef<DatePicker>(null);
    // const [field] = useField(name || '');
    const [temporaryDate, setTemporaryDate] = useState<Date| undefined>(startDate ? new Date(startDate) : undefined);

    const handleCalendarClose = () => {
        if (startDate){
            setTemporaryDate(new Date(startDate));
            onChange(startDate);    
        }
        else{
            setTemporaryDate(undefined);
        }
    };

    useEffect(()=>{
        if (startDate){
            setTemporaryDate(new Date(startDate));
        }
    }, [startDate]);

    const saveDate = (e: React.MouseEvent<HTMLButtonElement>) =>{
        e.preventDefault();
        e.stopPropagation();
        onChange(temporaryDate?.toISOString());
        datePickerRef?.current?.setOpen(false); 
    };

    const cancel = (e: React.MouseEvent<HTMLButtonElement>) =>{
        e.preventDefault();
        e.stopPropagation();
        datePickerRef?.current?.setOpen(false);
    };

    const onChangeTemporary = (date: Date)=>{
        if(date){
            setTemporaryDate(date);
        }
    };

    const yearsOptions = useMemo(()=>{
        const currentYearNumber = parseInt(currentYear);
        let options = [];
        for(let y=-2; y<2; y++){
            options.push({
                value: currentYearNumber + y, label: currentYearNumber + y
            });
        }
        return options;

    }, []);

    const getMonth = useCallback((date: Date)=>{
        const startingMonthNumber = parseInt(format(date, 'M'));
        const startingMonthOption = options.find(option=>option.value === startingMonthNumber);
        return startingMonthOption;
    }, []);

    const handleEnterKey = (e: any)=>{
        if (e.key === 'Enter') {
            saveDate(e);
        }
    };

    const getYear = useCallback((date: Date)=>{
        const startingYearNumber = parseInt(format(date, 'yyyy'));
        const startingYearOption = yearsOptions.find(option=>option.value === startingYearNumber);
        return startingYearOption;
    }, [yearsOptions]);

    return (
        <StyledDatePicker hasError={hasError}>
        <DatePicker
                name={name}
                onCalendarClose={handleCalendarClose}
                onBlur={onBlur}
                onKeyDown={handleEnterKey}
                customInput={<ReactDatePickerInput name={name} />}
                onChangeRaw={onBlur}
                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                }) => (
                    <div
                    style={{
                        margin: 10,
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                    >
                        <label>
                            <FormElement.Select
                                $borderless={true}
                                isSearchable={false}
                                value={getYear(date)}
                                onChange={(e: {value: number, label: string})=>{
                                    if (e){
                                        changeYear(e.value);
                                    }
                                }}
                                options={yearsOptions}
                            />
                        </label>
                        <label>
                            <StyledDateSelect>
                                <FormElement.Select
                                    $borderless={true}
                                    isSearchable={false}
                                    value={getMonth(date)}
                                    onChange={(e: {value: number, label: string})=>{
                                        if (e){
                                            changeMonth(e.value - 1);
                                        }
                                    }}
                                    options={options}
                                />
                            </StyledDateSelect>
                        </label>
                    </div>
                )}
        dateFormat="dd.MM.yyyy" 
        selected={temporaryDate} 
        onChange={onChangeTemporary} 
        shouldCloseOnSelect={false}
        calendarStartDay={1}
        ref={datePickerRef}
        >
            <StyledButtonContainer>
                <div>
                    <Button.White onClick={cancel}>Cancel</Button.White>
                    <Button.Primary onClick={saveDate}>Set date</Button.Primary>
                </div>
            </StyledButtonContainer>
        </DatePicker>
        </StyledDatePicker>
    );
};

interface IStyledDatePicker{
    hasError?: boolean
}

const StyledDatePicker = styled.div<IStyledDatePicker>`
    input[type=text]{
        border: 1px solid ${({theme, hasError})=>hasError ? lighten('0.1', theme.palette.danger) : 'inherit'};
    }
`;


const StyledDateSelect = styled.div`
    >*{
        min-width: 140px;
    }
`;

const StyledButtonContainer = styled.div`
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    >div{
        display: flex;
        width: 100%;
        padding: ${({theme})=>theme.spacing[2]};
        justify-content: space-between;
        >button{
            width: calc(50% - 8px);
        }
    }
`;

export default DatepickerComponent;