import { API } from 'aws-amplify';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateWorkPackageMutation, CreateWorkPackageMutationVariables } from '../../API';
import { createWorkPackage } from '../../graphql/mutations';

interface IaddWorkPackageThunk{
    name: string,
    startDate: string, 
    endDate?: string,
    projectId: string
};

export const addWorkPackageThunk = createAsyncThunk('currentProject/addWorkPackage', async ({name, startDate, endDate, projectId}: IaddWorkPackageThunk) => {
    const variables : CreateWorkPackageMutationVariables = {
        input: {
            name: name,
            startDate: startDate,
            endDate: endDate,
            projectWorkPackagesId: projectId,
        }
    };
    const apiData = (await API.graphql({ query: createWorkPackage, variables: variables})) as { data: CreateWorkPackageMutation };    
    const workPackage = apiData.data.createWorkPackage;
    return workPackage;
});
