import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Elements from '../../../components/shared/Elements';
import FormElement from '../../../components/shared/FormElement';
import Icon from '../../../components/shared/Icon';
import Modal from '../../../components/shared/Modal';
import { useModal } from '../../../components/shared/Modal/useModal';
import useDebounce from '../../../hooks/useDebounce';
import { updateWorkPackageRole, updateWorkPackageEmployee } from '../../../reducers/currentProjectReducer';
import { RootState, useAppDispatch } from '../../../store';
import {
  WorkPackageRoleType,
  WorkPackageType,
} from '../../../types/workPackage.type';
import {
  capacityOptions,
  ICapacityOption,
  removeWorkPackageRoleAPI,
  updateWorkPackageRoleAPI,
} from '../workPackageHelper';
import { IRoleOption, IEmployeeOption } from '../WorkPackages/workPackages.type';
import RoleDetails from './RoleDetails';
import { StyledDeleteRole } from './workPackage.styled';
import { listEmployeesThunk } from '../../../reducers/employeeList/listEmployees';

interface IRoleContainer {
  workPackage: WorkPackageType;
  role: WorkPackageRoleType;
}

const RoleContainer = ({ role, workPackage }: IRoleContainer): JSX.Element => {

  const employees = useSelector((state: RootState) => state.employees.employees);
 
  const defaultRoles = useSelector(
    (state: RootState) => state.currentProject.defaultRoles
  );

  const [personId, setPersonId] = useState<string | null>(null);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>(null);
  
  const debouncedRole: WorkPackageRoleType = useDebounce(role, 500);
  const dispatch = useAppDispatch();

  const confirmRemoveRoleModal = useModal();

  const roleOptions = useMemo(() => {
    if (!defaultRoles) {
      return [];
    }
    return defaultRoles.map((role) => {
      return {
        label: role.name,
        value: role.id,
      };
    });
  }, [defaultRoles]);

 

  const employeeOptions = useMemo(() => {
    if (!employees) {
      return [];
    }
    return employees.map((employee) => {
      return {
        label: employee.name,
        value: employee.id,
      };
    });
  }, [employees]);



  const removeRole = (role: WorkPackageRoleType) => {
    removeWorkPackageRoleAPI(role.id);
  };



  useEffect(() => {
    async function getEmployeeList() {
      dispatch(listEmployeesThunk());
    }
    getEmployeeList();
    updateWorkPackageRoleAPI(debouncedRole);
  }, [dispatch, debouncedRole]);

  console.log("employee", employees);
  console.log('employeeOptions', employeeOptions);

console.log("selectedEmployeeId", selectedEmployeeId);

  

  return (
    <>
      <StyledContainer key={role.id}>
        <StyledSelectContainer>
        <FormElement.Select
            value={
              roleOptions
                ? roleOptions.find(
                    (option) => option.value === role.rolePackagesId
                  )
                : ''
            }
            onChange={(option: IRoleOption) => {
              dispatch(
                updateWorkPackageRole({ ...role, rolePackagesId: option.value })
              );
            }}
            options={roleOptions}
          />
         
          <FormElement.Select
            placeholder={'Capacity'}
            value={
              capacityOptions
                ? capacityOptions.find(
                    (option) => option.value === role.capacity
                  )
                : ''
            }
            onChange={(option: ICapacityOption) => {
              dispatch(
                updateWorkPackageRole({ ...role, capacity: option.value })
              );
            }}
            options={capacityOptions}
          />
        </StyledSelectContainer>
        <StyledSelectContainer>
        <FormElement.Select
  value={
    employeeOptions
      ? employeeOptions.find((option) => option.value === selectedEmployeeId)
      : ''
  }
  onChange={(option: IEmployeeOption) => {
    dispatch(
      updateWorkPackageRole({ ...role, employeePackagesId: option.value })
    );
    setSelectedEmployeeId(option.value);  
  }}
  options={employeeOptions}
/>




        </StyledSelectContainer>

        <StyledDetailsContainer>
          {role.capacity && (
            <RoleDetails role={role} workPackage={workPackage} />
          )}
          <StyledDeleteRole
            onClick={() => {
              confirmRemoveRoleModal.toggle();
            }}
          >
            <Elements.Tooltip text="Delete role">
              <Icon.X />
            </Elements.Tooltip>
          </StyledDeleteRole>
        </StyledDetailsContainer>
      </StyledContainer>

      <Modal.Confirm
        header={'Delete role'}
        text={'All dates set within the role will be lost.'}
        confirmText={'Delete role'}
        toggle={confirmRemoveRoleModal.toggle}
        confirm={() => {
          removeRole(role);
        }}
        isShown={confirmRemoveRoleModal.isShown}
      />
    </>
  );
};

export default RoleContainer;

const StyledDetailsContainer = styled.div`
  display: flex;
`;

const StyledSelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing[1]};

  > *:first-child {
    width: 60%;
    margin-right: ${({ theme }) => theme.spacing[1]};
  }
  > * {
    flex-grow: 1;
  }
`;

const StyledContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.elements.line};
  padding: ${({ theme }) => theme.spacing[2]} ${({ theme }) => theme.spacing[3]} ${({ theme }) => theme.spacing[1]};
`;
