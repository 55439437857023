import styled from 'styled-components';
import RoleContainer from './RoleContainer';
import PackageSummary from './PackageSummary/PackageSummary';
import {
  StyledWorkPackageDetails,
  StyledWorkPackageDetailsContainer,
} from './workPackage.styled';
import { WorkPackageType } from '../../../types/workPackage.type';
import PackageCalendarContainer from './PackageCalendar/PackageCalendarContainer';
import { getWeekPeriods } from '../workPackageHelper';
import { useMemo } from 'react';
import AddRoleContainer from './AddRoleContainer';

interface IWorkPackageDetails {
  workPackage: WorkPackageType;
  workPackageIndex: number;
}

const WorkPackageDetails = ({
  workPackage,
  workPackageIndex,
}: IWorkPackageDetails): JSX.Element => {
  // const weeks = useMemo(() => {
  //   if (!workPackage.endDate) return [];
  //   return getWeekPeriods(
  //     new Date(workPackage.startDate),
  //     new Date(workPackage.endDate)
  //   );
  // }, [workPackage.startDate, workPackage.endDate]);

  return (
    <StyledWorkPackageDetailsContainer>
      <StyledWorkPackageDetails>
        <StyledContainer></StyledContainer>

        {workPackage.roles?.items?.map((role) => (
          <RoleContainer key={role.id} role={role} workPackage={workPackage} />
        ))}

        <AddRoleContainer workPackage={workPackage} />
        <PackageSummary workPackage={workPackage} />
      </StyledWorkPackageDetails>

      <PackageCalendarContainer workPackage={workPackage} />
    </StyledWorkPackageDetailsContainer>
  );
};

const StyledContainer = styled.div`
  padding: ${({ theme }) => theme.spacing[2]} ${({ theme }) => theme.spacing[3]};
  height: 64px;
`;

export default WorkPackageDetails;
