import { FC } from 'react';
import styled from 'styled-components';

const StyledErrorMessage = styled.div`
    color: ${({theme})=>theme.palette.danger};
    margin-top: ${({theme})=>theme.spacing[1]};
`;

interface IErrorMessage {
    show?: boolean,
    message? : string
};

const ErrorMessage : FC<IErrorMessage> = ({show = true, message})=>(
    <>{show && 
        <StyledErrorMessage>{message}</StyledErrorMessage>}
    </>
);

export default ErrorMessage;