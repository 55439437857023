import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Icon from '../../../../components/shared/Icon';
import StyledIcon from '../../../../components/shared/Icon/StyledIcon';
import { RootState } from '../../../../store';
import { WorkPackageRoleType, WorkPackageType } from '../../../../types/workPackage.type';
import { calculatePackageSummary, calculateWorkingDays } from '../../workPackageHelper';
import { StyledSummaryBlock } from './packageSummary.styled';
import PackageSummaryTooltip from './PackageSummaryTooltip';
import RoleSummary from './RoleSummary';

interface IPackageSummary{
    workPackage: WorkPackageType,
}

export type RolesTotalsRoleType = {
    role: WorkPackageRoleType['id'],
    days: number,
    cost: number
}

export type RolesTotalsType = {
    roles: RolesTotalsRoleType[],
    cost: number,
    days: number
};

const PackageSummary = ({workPackage} : IPackageSummary ) : JSX.Element =>{
    const defaultRoles = useSelector((state: RootState) => state.currentProject.defaultRoles);

    const packageSummaryCalculation = useMemo(()=>calculatePackageSummary(workPackage, defaultRoles), [workPackage, defaultRoles]);
    const packageSummaryCalculationFormat = useMemo(()=>{
        return '€'+(packageSummaryCalculation/1000).toString().replace('.', ',');
    }
    , [packageSummaryCalculation]);

    const rolesTotals = useMemo(()=>{
        let totals: RolesTotalsType = {
            days: 0,
            cost: 0,
            roles: []
        };

        workPackage.roles?.items?.forEach(role=>{
            if (workPackage.startDate && workPackage.endDate && role.capacity){
                const defaultRole = defaultRoles.find(r=>r.id === role.rolePackagesId);    
                if (defaultRole){
                    let roleCostTotal = 0;
                    const roleDaysTotal = calculateWorkingDays(new Date(workPackage.startDate), new Date(workPackage.endDate), role.capacity);
                    roleCostTotal = defaultRole.defaultRate.value * roleDaysTotal;
                    const existingRoleTotals = totals.roles.find(r=>r.role === role.rolePackagesId);
                    if (existingRoleTotals){
                        existingRoleTotals.cost += roleCostTotal;
                        existingRoleTotals.days += roleDaysTotal;
                    }
                    else{
                        totals.roles.push({
                            role: defaultRole?.id,
                            days: roleDaysTotal,
                            cost: roleCostTotal
                        });
                    }
                }

            }
        });
        totals.roles.forEach(role=>{
            totals.days += role.days;
            totals.cost += role.cost; 
        });
        return totals;

    }
    , [workPackage?.roles?.items, workPackage.startDate, workPackage.endDate, defaultRoles]);


    return (
        <StyledContainer>
            <StyledSummaryBlock>
                <b>Package summary </b>
                <span>
                    <PrimarySummary>
                        {packageSummaryCalculationFormat}k
                        {!!workPackage?.roles?.items?.length &&
                            <>
                                <Icon.InfoSummary />
                                <StyledPackageSummaryTooltip>
                                    <PackageSummaryTooltip roleTotals={rolesTotals} workPackage={workPackage} />
                                </StyledPackageSummaryTooltip>
                            </>
                        }
                    </PrimarySummary>
                </span>
            </StyledSummaryBlock>
            {rolesTotals.roles?.map((role)=>(
                <RoleSummary key={role.role} workPackage={workPackage} role={role} />
            ))}
        </StyledContainer>
    );
};

export default PackageSummary;

const StyledContainer = styled.div`
    padding: ${({theme})=>theme.spacing[1]} ${({theme})=>theme.spacing[3]};
`;

const StyledPackageSummaryTooltip = styled.div``;

const PrimarySummary = styled.div`
    color: ${({theme})=>theme.palette.primary};
    display: flex;
    font-weight: 500;
    align-items: center;
    position: relative;
    ${StyledIcon}{
        margin-left: 5px;
    }
    ${StyledPackageSummaryTooltip}{
        display: none;
    }
    &:hover{
        ${StyledPackageSummaryTooltip}{
            display: block;
        }
    }
`;

