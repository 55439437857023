import Microsoft from './MicrosoftIcon';
import Users from './UsersIcon';
import Folder from './FolderIcon';
import Close from './CloseIcon';
import Trash from './TrashIcon';
import Info from './InfoIcon';
import X from './XIcon';
import InfoSummary from './InfoSummaryIcon';
import NoResults from './NoResultsIcon';

const Icon = {
  Microsoft,
  Users,
  Folder,
  Close,
  Trash,
  Info,
  InfoSummary,
  X,
  NoResults,
};

export default Icon;
