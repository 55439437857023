import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../components/shared/Button';
import Elements from '../../components/shared/Elements';
import Panel from '../../components/shared/Panel';
import { listProjectsThunk } from '../../reducers/projectList/listProjects';
import { RootState, useAppDispatch } from '../../store';
import ProjectList from './ProjectList';

const ProjectListPage = () => {
  const dispatch = useAppDispatch();
  const projects = useSelector((state: RootState) => state.projects.projects);
  const projectsStatus = useSelector(
    (state: RootState) => state.projects.projectsStatus
  );

  useEffect(() => {
    async function getProjectList() {
      dispatch(listProjectsThunk());
    }
    getProjectList();
  }, [dispatch]);

  return (
    <Panel.Container fullScreen={true} flexDirection="column">
      <Panel.Header>
        <h1>Project List</h1>
        {/* <Elements.Link to="new-project">
          <Button.Primary>Add new</Button.Primary>
        </Elements.Link> */}
      </Panel.Header>
      {projectsStatus === 'loading' ? (
        <p>Loading</p>
      ) : (
        <ProjectList projects={projects} />
      )}
    </Panel.Container>
  );
};

export default ProjectListPage;
