import { createSlice } from '@reduxjs/toolkit';
import loadingState from '../types/loadingState.type';
import { projectListType } from '../types/projectList.type';
import { deleteProjectThunk } from './currentProject/deleteProject';
import { listProjectsThunk } from './projectList/listProjects';

export interface ProjectsState {
    projects: projectListType,
    projectsStatus: loadingState,
}

const initialState: ProjectsState = {
    projects: [],
    projectsStatus: 'loading'
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(deleteProjectThunk.fulfilled, (state, action) => {
        if (action.payload){
          const deletedProject = action.payload;
          state.projects = state.projects.filter((project)=> project?.id !== deletedProject.id);
        }
      })
      .addCase(listProjectsThunk.pending, (state, action) => {
        state.projectsStatus = 'loading';
      })
      .addCase(listProjectsThunk.fulfilled, (state, action) => {
        if (action.payload){
          state.projectsStatus = 'succeeded';
          let projects = action.payload;
          projects.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1);
          state.projects = projects;
        }
      })
      .addCase(listProjectsThunk.rejected, (state, action) => {
        state.projectsStatus = 'failed';
      });
  }
});

// Action creators are generated for each case reducer function
// export const {  } = projectsSlice.actions;

export default projectsSlice.reducer;