import { API } from 'aws-amplify';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createUnavailablePeriod } from '../../graphql/mutations';
import { CreateUnavailablePeriodInput } from '../../API';

export const addUnavailablePeriodThunk = createAsyncThunk(
  'currentEmployee/addUnavailablePeriod',
  async (unavailablePeriod: CreateUnavailablePeriodInput) => {
    const response = await API.graphql({
      query: createUnavailablePeriod,
      variables: { input: unavailablePeriod },
    });
    return response;
  }
);
