import styled from 'styled-components';
import IconStyled from './StyledIcon';
import TrashImage from './../../../assets/icons/trash.svg';

const TrashIcon = styled(IconStyled)`
    background-image: url(${TrashImage});
    background-size: contain;
    background-repeat: no-repeat;
`;

export default TrashIcon;