import { API } from 'aws-amplify';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DeleteWorkPackageMutation, DeleteWorkPackageMutationVariables } from '../../API';
import { deleteWorkPackage } from '../../graphql/mutations';

export const deleteWorkPackageThunk = createAsyncThunk('currentProject/deleteWorkPackage', async (id: string) => {
    const variables : DeleteWorkPackageMutationVariables = {
        input: {id: id}
    };
    const apiData = (await API.graphql({ query: deleteWorkPackage, variables: variables})) as { data: DeleteWorkPackageMutation };    
    const deletedWorkPackage = apiData.data.deleteWorkPackage;
    return deletedWorkPackage;
});
