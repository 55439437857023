import styled from 'styled-components';

interface IPanelContainer {
  readonly fullScreen?: boolean;
  readonly height?: string;
  flexDirection?: string;
  alignItems?: string;
  justifyContent?: string;
}

const PanelContainer = styled.section<IPanelContainer>`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  align-items: ${(props) => props.alignItems};
  height: ${(props) => (props.height ? '100%' : 'initial')};
  justify-content: ${(props) => props.justifyContent};
  /* background-color: ${({ theme }) => theme.background.secondary};
  padding: ${({ theme }) => theme.spacing[3]} ${({ theme }) => theme.spacing[4]}
    ${({ theme }) => theme.spacing[3]};
  margin-bottom: calc(
    ${({ theme, fullScreen }) => (fullScreen ? 0 : theme.spacing[2])}
  );
  min-height: ${(props) => (props.fullScreen ? '100%' : 'auto')}; */
`;

export default PanelContainer;
