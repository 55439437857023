import React, { FC, useEffect } from 'react';
import FocusLock from 'react-focus-lock';
import ReactDOM from 'react-dom';

import {
  Wrapper,
  Backdrop
} from './modal.style';

export interface IModalProps {
  isShown: boolean,
  toggle: () => void,
}

const modalRoot = document.querySelector('#modal');

export const ModalContainer: FC<IModalProps> = ({
  isShown,
  toggle,
  children
}) => {

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.keyCode === 27 && isShown) {
        toggle();
      }
    };
    isShown
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
    document.addEventListener('keydown', onKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [isShown, toggle]);

  const modal = (
    <React.Fragment>
      <Backdrop onClick={toggle} />
      <FocusLock>
        <Wrapper
          aria-modal
          tabIndex={-1}
          role="dialog"
        >
          {children}
        </Wrapper>
      </FocusLock>
    </React.Fragment>
  );

  return modalRoot && isShown ? ReactDOM.createPortal(modal, modalRoot) : null;
};

