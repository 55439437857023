import React, { FC } from 'react';
import styled from 'styled-components';
import FolderIcon from '../../shared/Icon/FolderIcon';
import HappyIcon from '../../shared/Icon/HappyIcon';
import UsersIcon from '../../shared/Icon/UsersIcon';
import { Tab } from '../../shared/Tabs/tab.type';
import TabContainer from '../../shared/Tabs/TabContainer';

const StyledHeaderNavigation = styled.div`
  flex-grow: 1;
`;

const HeaderNavigation: FC = () => {
  const headerRoutes: Tab[] = [
    { label: 'Projects', route: '/projects', id: '1' },
    { label: 'Project Control', route: '/project-control', id: '4' },
    { label: 'Employees', route: '/employees', id: '2' },
    { label: 'Clients', route: '/clients', id: '5' },
    // {
    //   label: 'Style guidelines',

    //   route: '/style-guidelines',
    //   id: '3',
    // },
  ];

  return (
    <StyledHeaderNavigation>
      <TabContainer tabs={headerRoutes} />
    </StyledHeaderNavigation>
  );
};

export default HeaderNavigation;
