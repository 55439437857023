import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Tab } from './tab.type';

interface ITabLink {
  readonly activeClassName?: string;
}

const TabLink: FC<Tab> = ({ route, label, icon }) => {
  return (
    <StyledTabLink
      className={({ isActive }) => (isActive ? 'active' : '')}
      to={route}
    >
      {icon && (
        <StyledIcon>
          <>{icon}</>
        </StyledIcon>
      )}
      {label}
    </StyledTabLink>
  );
};

const StyledIcon = styled.div`
  margin-right: 0.8em;
`;

const StyledTabLink = styled(NavLink)<ITabLink>`
  padding: calc(${({ theme }) => theme.elements.spacing} / 2);
  font-weight: 500;
  color: ${({ theme }) => theme.text.white};
  text-decoration: none;
  border-radius: ${({ theme }) => theme.elements.borderRadius};
  display: flex;
  line-height: 1.3em;
  font-size: 1.4rem;
  align-items: center;
  background: 'transparent';
  opacity: 0.4;

  ${StyledIcon} {
    opacity: 0.5;
  }

  &.active {
    opacity: 1;

    ${StyledIcon} {
      opacity: 1;
    }
  }
`;

export default TabLink;
