import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Project } from '../../API';
import Button from '../../components/shared/Button';
import Elements from '../../components/shared/Elements';
import Table from '../../components/shared/Table';
import { projectListType } from '../../types/projectList.type';
import Pagination from '@mui/material/Pagination';

const ROWS_PER_PAGE = 5;

interface IProjectList {
  projects: projectListType;
}

const ProjectList = ({ projects }: IProjectList): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const [selectedProject, setSelectedProject] =
    useState<string>('All Projects');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (): void => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setSelectedProject(event.target.value);
  };

  const filteredProjects = projects.filter((project) =>
    selectedProject === 'All Projects'
      ? project.name.toLowerCase().includes(searchTerm.toLowerCase())
      : project.name === selectedProject.toLowerCase()
  );

  const sortedProjects = filteredProjects.sort((a, b) => {
    if (sortOrder === 'asc') {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  });

  const projectOptions = [
    'All Projects',
    ...Array.from(new Set(projects.map((project) => project.name))),
  ];

  const goToProject = (projectId: Project['id'] | undefined): void => {
    if (projectId) {
      navigate(`/projects/${projectId}`);
    }
  };

  console.log([projectOptions]);

  console.log('selected project: ', selectedProject);

  return (
    <>
      <FilterContainer>
        <FilterLeft>
          {/* <Select options={options} /> */}
          {/* <Select
            value={selectedProject}
            onChange={handleSelect as any}
            options={optionsNew as any}
          /> */}
          {/* <FilterSelect value={selectedProject} onChange={handleSelect}>
            {projectOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </FilterSelect> */}
          <FilterSelect value={selectedProject} onChange={handleSelect}>
            {projectOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </FilterSelect>
        </FilterLeft>
        <div>
          <Elements.Link to="new-project">
            <Button.Primary>New Project</Button.Primary>
          </Elements.Link>
        </div>
      </FilterContainer>

      <Table.Wrapper>
        <SearchInputContainer>
          <SearchInput
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearch}
          />
        </SearchInputContainer>

        <Table.Container>
          <thead>
            <tr>
              <Table.Th>
                <div>
                  Name
                  <SortButton onClick={handleSort}>
                    {sortOrder === 'asc' ? '↑' : '↓'}
                  </SortButton>
                </div>
              </Table.Th>
           
              <Table.Th>Customer</Table.Th>
              <Table.Th>Customer</Table.Th>
              <Table.Th>Project Number</Table.Th>
              <Table.Th>Project Status</Table.Th>
            </tr>
          </thead>
          <tbody>
            {!sortedProjects.length && (
              <Table.Tr noHover>
                <Table.Td>
                  <StyledNoResultsContainer>
                    <h3>Project list is empty</h3>
                    <p>Add a project to see it in this list.</p>
                    <Elements.Link to="new-project">
                      <Button.Primary>New Project</Button.Primary>
                    </Elements.Link>
                  </StyledNoResultsContainer>
                </Table.Td>
              </Table.Tr>
            )}
            {sortedProjects
              .slice(
                (currentPage - 1) * ROWS_PER_PAGE,
                currentPage * ROWS_PER_PAGE
              )
              .map((project) => (
                <Table.Tr
                  key={project?.id}
                  onClick={() => {
                    goToProject(project?.id);
                  }}
                >
                  <Table.Td>{project?.name}</Table.Td>
        
                  <Table.Td>PAG</Table.Td>
                  <Table.Td>Dr.-Ing. h.c. F. Porsche AG</Table.Td>
                  <Table.Td>SYD-0011</Table.Td>
               
                  <Table.Td>
                    <Elements.Status>ongoing</Elements.Status>
                  </Table.Td>
                </Table.Tr>
              ))}
          </tbody>
          <Pagination
            count={Math.ceil(sortedProjects.length / ROWS_PER_PAGE)}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            variant="outlined"
            shape="rounded"
            style={{ margin: '1rem' }}
          />
        </Table.Container>
      </Table.Wrapper>
    </>
  );
};

const FilterLeft = styled.div`
  display: flex;
`;
const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const StyledNoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  text-align: center;
`;
const SearchInputContainer = styled.div`
  width: 50%;
  max-width: 400px;
  padding: 2rem;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 1.25rem 2rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
`;

const FilterSelect = styled.select`
  width: 100%;
  padding: 12px 12px 12px 12px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  color: #585858;
  max-width: initial;
  min-width: initial;
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

const SortButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 0.5rem;
`;

export default ProjectList;
