import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { FC, useEffect } from 'react';

const StyledReactTooltip = styled(ReactTooltip)`
    &.__react_component_tooltip{
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 1.2rem;
        line-height: 1.6rem;
        opacity: 1;
        background-color: ${({theme})=>theme.palette.gray10};
    }

    &::before, &::after{
        display: none;
    }
`;

interface IToolTip{
    text: string,
}

const Tooltip: FC<IToolTip>= ({text, children, ...props})=>{

    useEffect(()=>{
        return (()=>{
            ReactTooltip.hide();
        });
    }, []);
    
    return (
        <>  
            <div data-tip={text}>{children}</div>
            <StyledReactTooltip {...props} />
        </>
    );
};

export default Tooltip;