import styled from 'styled-components';
import IconStyled from './StyledIcon';
import NoResultsImage from './../../../assets/icons/noresults.svg';

const NoResultsIcon = styled(IconStyled)`
  background-image: url(${NoResultsImage});
  background-size: contain;
  background-repeat: no-repeat;
  width: 300px;
  height: 200px;
  margin: 0 auto;
`;

export default NoResultsIcon;
