import styled from 'styled-components';

export interface IModalProperties{
  $zIndex? : number
}

export const Wrapper = styled.div<IModalProperties>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${({$zIndex})=> $zIndex ? $zIndex * 1000 : 1000};
  width: inherit;
  outline: 0;
`;

export const Backdrop = styled.div<IModalProperties>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: ${({$zIndex})=> $zIndex ? $zIndex * 500 : 500};
`;

export interface IStyledModal{
  $fullscreen? : boolean
}

export const StyledModal = styled.div<IStyledModal>`
  z-index: 100;
  background: white;
  position: relative;
  margin: auto;
  width: ${({$fullscreen})=>$fullscreen ? '100vw' : 'auto'};
  height: ${({$fullscreen})=>$fullscreen ? '100vh' : 'auto'};
`;

export const HeaderText = styled.div`
  color: #fff;
  align-self: center;
  color: lightgray;
`;

export const CloseButton = styled.div`
  padding: ${({theme})=>theme.spacing[2]};
  margin-left: -${({theme})=>theme.spacing[2]};
  :hover {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
`;

export const Body = styled.div`
      padding: ${({theme})=>theme.spacing[3]};
`;