import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Employee } from '../../../API';
import dayjs from 'dayjs';
import Button from '../../../components/shared/Button';
import Elements from '../../../components/shared/Elements';
import Table from '../../../components/shared/Table';
import { employeeListType } from '../../../types/employeeList.type';
import Pagination from '@mui/material/Pagination';


const ROWS_PER_PAGE = 5;

interface IEmployeeList {
  employees: employeeListType;
}

const EmployeeList = ({ employees }: IEmployeeList): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  

  const [selectedProject, setSelectedProject] =
    useState<string>('All Employees');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (): void => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setSelectedProject(event.target.value);
  };

  const filteredProjects = employees.filter((employee) =>
    selectedProject === 'All Employees'
      ? employee.name.toLowerCase().includes(searchTerm.toLowerCase())
      : employee.name === selectedProject.toLowerCase()
  );

  const sortedProjects = filteredProjects.sort((a, b) => {
    if (sortOrder === 'asc') {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  });

  const projectOptions = [
    'All Employees',
    ...Array.from(new Set(employees.map((employee) => employee.name))),
  ];

  const goToEmployee = (employeeId: Employee['id'] | undefined): void => {
    if (employeeId) {
      navigate(`/employees/${employeeId}`);
    }
  };

  


  return (
    <>
      <FilterContainer>
        <FilterLeft>
        <FilterSelect value={selectedProject} onChange={handleSelect}>
            {projectOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </FilterSelect>
        
        </FilterLeft>
        <div>
          <Elements.Link to="new-employee">
            <Button.Primary>New Employee</Button.Primary>
          </Elements.Link>
        </div>
      </FilterContainer>

      <Table.Wrapper>
        <SearchInputContainer>
          <SearchInput
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearch}
          />
        </SearchInputContainer>

        <Table.Container>
          <thead>
            <tr>
              <Table.Th>
                <div>
                  Name
                  <SortButton onClick={handleSort}>
                    {sortOrder === 'asc' ? '↑' : '↓'}
                  </SortButton>
                </div>
              </Table.Th>
              <Table.Th>Date Created</Table.Th>
              <Table.Th>Type</Table.Th>
              <Table.Th>Role</Table.Th>
              <Table.Th>Availability</Table.Th>
            </tr>
          </thead>
          <tbody>
           
            {sortedProjects
              .slice(
                (currentPage - 1) * ROWS_PER_PAGE,
                currentPage * ROWS_PER_PAGE
              )
              .map((employee) => (
                <Table.Tr
                  key={employee?.id}
                  onClick={() => {
                    goToEmployee(employee?.id);
                  }}
                >
                  <Table.Td>{employee?.name}</Table.Td>
                  <Table.Td>{dayjs(employee?.createdAt).format('MMM D, YYYY')}</Table.Td>
                  <Table.Td>Freelancer</Table.Td>
                  <Table.Td>Consultant</Table.Td>
                  <Table.Td></Table.Td>
                
                </Table.Tr>
              ))}
          </tbody>
          <Pagination
            count={Math.ceil(sortedProjects.length / ROWS_PER_PAGE)}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            variant="outlined"
            shape="rounded"
            style={{ margin: '1rem' }}
          />
        </Table.Container>
         {!sortedProjects.length && (
            
                  <StyledNoResultsContainer>
                    <h3>Employee list is empty</h3>
                    <p>Add a project to see it in this list.</p>
                    <Elements.Link to="new-employee">
                      <Button.Primary>New Employee</Button.Primary>
                    </Elements.Link>
                  </StyledNoResultsContainer>
          
            )}
      </Table.Wrapper>
    </>
  );
};

const FilterLeft = styled.div`
  display: flex;
`;
const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const StyledNoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  text-align: center;
  justify-content: center;
`;
const SearchInputContainer = styled.div`
  width: 50%;
  max-width: 400px;
  padding: 2rem;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 1.25rem 2rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
`;

const FilterSelect = styled.select`
  width: 100%;
  padding: 12px 12px 12px 12px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  color: #585858;
  max-width: initial;
  min-width: initial;
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

const SortButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 0.5rem;
`;

export default EmployeeList;
