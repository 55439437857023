import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userReducer';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { useDispatch } from 'react-redux';
import projectsReducer from './reducers/projectsReducer';
import employeesReducer from './reducers/employeesReducer';
import currentProjectReducer from './reducers/currentProjectReducer';
import currentEmployeeReducer from './reducers/currentEmployeeReducer';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['user']
};

const rootReducer = combineReducers(
  { user: userReducer,
    projects: projectsReducer,
    currentProject: currentProjectReducer,
    employees: employeesReducer,
    currentEmployee: currentEmployeeReducer,
  },
  );

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
