import { API } from 'aws-amplify';
import { FC, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../components/shared/Button';
import Panel from '../../../components/shared/Panel';
import {
  currentEmployeeType,
  employeeType,
} from '../../../types/employee.type';
import { unavailableType } from '../../../types/unavailable.type';
import { updateEmployee } from '../../../graphql/mutations';
import FormElement from '../../../components/shared/FormElement';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useDispatch } from 'react-redux';
import { addUnavailablePeriodThunk } from '../../../reducers/currentEmployee/addUnavailablePeriod';

interface IEmployeeContainer {
  employee: currentEmployeeType;
  deleteEmployee: () => void;
  index: number;
}

const EmployeeContainer: FC<IEmployeeContainer> = ({
  employee,
  deleteEmployee,
  index,
}) => {
  console.log(employee);

  const dispatch = useDispatch();

  const [name, setName] = useState(employee.name);
  const [lastName, setLastName] = useState(employee.lastName);
  const [description, setDescription] = useState(employee.description);
  const [role, setRole] = useState(employee.role);
  const [employmentType, setEmploymentType] = useState(employee.employmentType);
  const [email, setEmail] = useState(employee.email);
  const [phone, setPhone] = useState(employee.phone);

  const [unavailablePeriodStartDate, setUnavailablePeriodStartDate] =
    useState('');
  const [unavailablePeriodEndDate, setUnavailablePeriodEndDate] = useState('');

  const [unavailablePeriods, setUnavailablePeriods] = useState(
    employee.unavailablePeriods
  );

  const addUnavailablePeriod = () => {
    dispatch(
      addUnavailablePeriodThunk({
        employeeId: employee.id,
        startDate: unavailablePeriodStartDate,
        endDate: unavailablePeriodEndDate,
      })
    );

    setUnavailablePeriodStartDate('');
    setUnavailablePeriodEndDate('');
  };

  const handleAddUnavailablePeriod = () => {
    addUnavailablePeriod();
  };

  const [activeTab, setActiveTab] = useState('Personal');

  // Function to update an employee
  const updateEmployeeMutation = async (
    id: string,
    name: string,
    email: string,
    phone: string
  ) => {
    try {
      const updatedEmployee = await API.graphql({
        query: updateEmployee,
        variables: {
          input: {
            id: id,
            name: name,
            email: email,
            phone: phone,
          },
        },
      });
      console.log('Employee updated:', updatedEmployee);
      // Add any additional logic you want to perform after updating the employee
    } catch (error) {
      console.error('Error updating employee:', error);
      // Handle the error appropriately
    }
  };

  const handleUpdateEmployee = () => {
    updateEmployeeMutation(employee.id, name, email ?? '', phone ?? '');
  };

  console.log('employee', employee);
  console.log('aa', employee.unavailable);

  return (
    <>
      <Panel.Container fullScreen={true} flexDirection="column">
        <EmployeeWrapper>
          <StyledAccordion
            defaultExpanded={true}
            TransitionProps={{ unmountOnExit: true }}
          >
            <StyledAccordionSummary
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ProjectInfo>
                <h1>{employee.name}</h1>
                <p>{employee.description}</p>
              </ProjectInfo>
              <div>
                {/* <Button.Danger onClick={deleteProject}>
                  Delete project
                </Button.Danger> */}
              </div>
            </StyledAccordionSummary>
            <AccordionDetails>
              <Attributes>
                <AttributeBlock>
                  <Attribute>
                    <h6>Company</h6>
                    <p>Dr.-Ing. h.c. F. Porsche AG</p>
                  </Attribute>
                  <Attribute>
                    <h6>Active Projects</h6>
                    <p>4</p>
                  </Attribute>
                  <Attribute>
                    <h6>Duration</h6>
                    <p>Apr 1, 2022 - Dec 31, 2023</p>
                  </Attribute>
                </AttributeBlock>
                <AttributeBlock>
                  <Attribute>
                    <h6>Related</h6>
                    <p>Add</p>
                  </Attribute>
                  <Attribute>
                    <h6>Status</h6>
                    <p>none</p>
                  </Attribute>
                  <Attribute>
                    <h6>Probability</h6>
                    <p>80%</p>
                  </Attribute>
                </AttributeBlock>
              </Attributes>
              <AttributeBlock>
                <Attribute>
                  <h6>Description</h6>
                  <p>{employee.description}</p>
                  aa <p>{employee.lastName}</p>
                </Attribute>
              </AttributeBlock>
            </AccordionDetails>
          </StyledAccordion>

          <ProjectTabs>
            <TabButton
              className={activeTab === 'Personal' ? 'active' : ''}
              onClick={() => setActiveTab('Personal')}
            >
              Personal Details
            </TabButton>
            <TabButton
              className={activeTab === 'Work' ? 'active' : ''}
              onClick={() => setActiveTab('Work')}
            >
              Staffing
            </TabButton>
            <TabButton
              className={activeTab === 'Contact' ? 'active' : ''}
              onClick={() => setActiveTab('Contact')}
            >
              Availability
            </TabButton>
          </ProjectTabs>

          {activeTab === 'Personal' && (
            <>
              <br /> <br />
              <FormElement.Control>
                <FormElement.Label>
                  Employee Name
                  <FormElement.Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormElement.Label>
              </FormElement.Control>
              <FormElement.Control>
                <FormElement.Label>
                  Employee Email
                  <FormElement.Input
                    value={String(email)}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormElement.Label>
              </FormElement.Control>
              <FormElement.Control>
                <FormElement.Label>
                  Employee Phone
                  <FormElement.Input
                    value={String(phone)}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </FormElement.Label>
              </FormElement.Control>
              <br /> <br />
              <Button.Primary onClick={handleUpdateEmployee}>
                Update
              </Button.Primary>
            </>
          )}

          {activeTab === 'Work' && (
            <>
              <p>Work</p>
            </>
          )}

          {activeTab === 'Contact' && (
            <>
              <h3>Unavailable Periods</h3>
              {employee.unavailablePeriods.items?.map(
                (unavailablePeriod: unavailableType) => (
                  <div key={unavailablePeriod.id}>
                    <p>
                      {unavailablePeriod.startDate} -{' '}
                      {unavailablePeriod.endDate}
                    </p>
                  </div>
                )
              )}
              <br />

              {unavailablePeriods.items?.map(
                (unavailablePeriod: unavailableType) => (
                  <div key={unavailablePeriod.id}>
                    <p>
                      {unavailablePeriod.startDate} -{' '}
                      {unavailablePeriod.endDate}
                    </p>
                  </div>
                )
              )}
              <br />
              <br />
              {employee.unavailablePeriods.items?.map(
                (unavailablePeriod: unavailableType) => (
                  <div key={unavailablePeriod.id}>
                    <p>
                      {unavailablePeriod.startDate} -{' '}
                      {unavailablePeriod.endDate}
                    </p>
                  </div>
                )
              )}

              <br />
              <br />

              <h3>Add Unavailable Period</h3>

              <FormElement.Control>
                <FormElement.Label>
                  Start Date
                  <FormElement.Input
                    type="date"
                    value={unavailablePeriodStartDate}
                    onChange={(e) =>
                      setUnavailablePeriodStartDate(e.target.value)
                    }
                  />
                </FormElement.Label>
              </FormElement.Control>

              <FormElement.Control>
                <FormElement.Label>
                  End Date
                  <FormElement.Input
                    type="date"
                    value={unavailablePeriodEndDate}
                    onChange={(e) =>
                      setUnavailablePeriodEndDate(e.target.value)
                    }
                  />
                </FormElement.Label>
              </FormElement.Control>

              <Button.Primary onClick={handleAddUnavailablePeriod}>
                Add Unavailable Period
              </Button.Primary>

              <br />
              <br />
            </>
          )}
        </EmployeeWrapper>
      </Panel.Container>
    </>
  );
};

const EmployeeWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing[4]} 0
    ${({ theme }) => theme.spacing[4]};
  .MuiAccordionDetails-root {
    padding: 0;
  }
  .MuiAccordion-root {
    box-shadow: none;
    .MuiAccordionSummary-root {
      padding: 0;
    }
  }
`;

const UnavailablePeriodWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing[4]} 0
    ${({ theme }) => theme.spacing[4]};
  border-bottom: 1px solid #e6e8ec;
`;

const ProjectTabs = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #e6e8ec;
`;

const TabButton = styled.button`
  padding: 14px 0rem;
  margin-right: 5rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  color: #9d9d9d;
  background: none;
  border: none;
  cursor: pointer;
  transition: 0.2s all;

  &:hover {
    background-color: none;
    color: #1d2226;
    transition: 0.2s all;
  }
  &.active {
    color: #1d2226;
    background: none;
  }
`;

const Attributes = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const AttributeBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Attribute = styled.div`
  display: flex;
  padding: 4px 0;
  align-items: center;

  h6 {
    font-size: 1.4rem;
    color: #808080;
    font-weight: 400;
    width: 180px;
    margin: 0;
  }
  p {
    font-size: 1.4rem;
    font-weight: 500;
    margin: 0;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
  }
  .MuiAccordionSummary-expandIconWrapper {
    background: #d8d8d8;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;

    svg {
      height: 24px;
      width: initial;
      color: #fff;
    }
  }
`;

const StyledAccordion = styled(Accordion)`
  margin-bottom: 1rem;
`;

const ProjectInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  h1 {
    font-size: 3.2rem;
    margin: 0;
  }
  p {
    margin: 0;
  }
`;

export default EmployeeContainer;
