import styled from 'styled-components';
import StyledIcon from './StyledIcon';
import InfoImage from './../../../assets/icons/information_summary.svg';

const InfoSummaryIcon = styled(StyledIcon)`
    background-image: url(${InfoImage});
    background-size: contain;
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
`;

export default InfoSummaryIcon;