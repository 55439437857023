import React from 'react';
import Panel from '../components/shared/Panel';

const People = ()=>{
    return (
        <Panel.Container>
            <Panel.Header>
                <h1>People</h1>
            </Panel.Header>
            {/* <Hr /> */}
        </Panel.Container>
    );
};

export default People;

