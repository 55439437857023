import styled from 'styled-components';

const PanelHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing[4]} 0
    ${({ theme }) => theme.spacing[4]};
  > h1,
  h2,
  h3 {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export default PanelHeader;
