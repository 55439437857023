import styled from 'styled-components';

interface ITrStyled{
    noHover? : boolean
}

const TrStyled = styled.tr<ITrStyled>`
    border-top: 1px solid ${({theme})=>theme.elements.line};
    cursor: ${({noHover = false})=> noHover ? 'inherit' : 'pointer'};
    &:hover{
        background-color: ${({theme, noHover = false})=> noHover ? 'inherit' : theme.background.default};
    }
`;

export default TrStyled;
