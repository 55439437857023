import styled from 'styled-components';

interface IPanelWrapper {
  readonly fullScreen?: boolean;
}

const PanelWrapper = styled.section<IPanelWrapper>`
  width: 100%;
  padding-top: 70px;
`;

export default PanelWrapper;
