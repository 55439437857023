
import Elements from '../../../components/shared/Elements';

const InvoicesTab = () => {
  return (
    <>
      <Elements.NoResults
        title="No invoices found"
        subtitle="Try adjusting your search or filter to find what you are looking for."
      />
    </>
  );
};

export default InvoicesTab;
