import styled from 'styled-components';

interface IHeaderWrapper {
  readonly fullScreen?: boolean;
}

const HeaderWrapper = styled.header<IHeaderWrapper>`
  position: absolute;
  height: 70px;
  width: 100%;
  background-color: ${({ theme }) => theme.background.secondary};
`;

export default HeaderWrapper;
