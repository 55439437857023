import { API } from 'aws-amplify';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateWorkPackageEmployeeMutation, UpdateWorkPackageEmployeeMutationVariables } from '../../API';
import { updateWorkPackageEmployee } from '../../graphql/mutations';
import { WorkPackageEmployeeType } from '../../types/workPackage.type';

export const updatePackageEmployeeThunk = createAsyncThunk('currentProject/updatePackageEmployee', async (employee: WorkPackageEmployeeType) => {
    const variables : UpdateWorkPackageEmployeeMutationVariables = {
        input: {
            id: employee.id,
            capacity: employee.capacity,
            employeeWorkPackagesId: employee.employeePackagesId
        }
    };
    const apiData = (await API.graphql({ query: updateWorkPackageEmployee, variables: variables})) as { data: UpdateWorkPackageEmployeeMutation };    
    const response = apiData.data.updateWorkPackageEmployee;
    return response;
});
