import styled from 'styled-components';

const Label = styled.label`
    color: ${({theme})=>theme.text.tetriary};
    font-size: 1.4rem;
    > *:first-child {
        margin-top: 0.4em;
    }
`;

export default Label;