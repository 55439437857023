import { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import FormElement from '../../../components/shared/FormElement';
import Icon from '../../../components/shared/Icon';
import { addWeeks, subWeeks } from 'date-fns';
import {
  createWorkPackageAPI,
  getWeekPeriods,
  removeWorkPackageAPI,
  updateWorkPackageAPI,
} from '../workPackageHelper';
import WorkPackageDetails from './WorkPackageDetails';
import { StyledDeleteIcon } from './workPackage.styled';
import { useModal } from '../../../components/shared/Modal/useModal';
import Modal from '../../../components/shared/Modal';
import { WorkPackageType } from '../../../types/workPackage.type';
import Elements from '../../../components/shared/Elements';
import WorkPackageNotifications from './WorkPackageNotifications';
import { RootState, useAppDispatch } from '../../../store';
import { useSelector } from 'react-redux';
import useDebounce from '../../../hooks/useDebounce';
import {
  removeWorkPackage,
  updateWorkPackage,
} from '../../../reducers/currentProjectReducer';
import { getProjectThunk } from '../../../reducers/currentProject/getProject';

interface IWorkPackageContainer {
  workPackage: WorkPackageType;
  index: number;
}

const WorkPackageContainer: FC<IWorkPackageContainer> = ({
  workPackage,
  index,
}) => {
  const [packageToDelete, setPackageToDelete] = useState<WorkPackageType>();
  const debouncedWorkPackage = useDebounce(workPackage, 500);
  const project = useSelector(
    (state: RootState) => state.currentProject.currentProject
  );
  const dispatch = useAppDispatch();
  const confirmRemovePackageModal = useModal();

  const getDuration = useMemo(() => {
    if (!workPackage.startDate || !workPackage.endDate) return;
    return (
      getWeekPeriods(
        new Date(workPackage.startDate),
        new Date(workPackage.endDate)
      ).length + ' weeks'
    );
    // return (
    //   getDaysPeriods(
    //     new Date(workPackage.startDate),
    //     new Date(workPackage.endDate)
    //   ).length + ' days'
    // );
  }, [workPackage.startDate, workPackage.endDate]);

  const deleteWorkPackage = () => {
    if (packageToDelete === undefined) {
      return;
    }
    // check if package exists on BE
    if (packageToDelete.projectWorkPackagesId) {
      removeWorkPackageAPI(packageToDelete.id)
        .unwrap()
        .then((deletedPackage) => {
          if (!deletedPackage && project) {
            dispatch(getProjectThunk(project.id));
          }
        });
    } else {
      dispatch(removeWorkPackage(packageToDelete));
    }
    confirmRemovePackageModal.toggle();
  };

  const removePackageHandler = (removedWorkPackage: WorkPackageType) => {
    setPackageToDelete(removedWorkPackage);
    confirmRemovePackageModal.toggle();
  };

  useEffect(() => {
    if (
      !debouncedWorkPackage.startDate ||
      !debouncedWorkPackage.endDate ||
      !debouncedWorkPackage.name ||
      !project
    ) {
      return;
    }

    if (!debouncedWorkPackage.projectWorkPackagesId) {
      createWorkPackageAPI(debouncedWorkPackage, project?.id).then(() => {
        dispatch(removeWorkPackage(debouncedWorkPackage));
      });
    } else {
      updateWorkPackageAPI(debouncedWorkPackage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedWorkPackage.startDate,
    debouncedWorkPackage.endDate,
    debouncedWorkPackage.name,
  ]);

  useEffect(() => {
    const startDate = new Date(workPackage.startDate);
    const endDate = workPackage.endDate ? new Date(workPackage.endDate) : null;

    if (!endDate || !startDate) {
      return;
    }

    if (startDate > endDate) {
      const newEndDate = addWeeks(startDate, 1);
      const updatedPackage: WorkPackageType = {
        ...workPackage,
        endDate: newEndDate.toDateString(),
      };
      dispatch(updateWorkPackage(updatedPackage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workPackage.startDate]);

  useEffect(() => {
    const startDate = new Date(workPackage.startDate);
    const endDate = workPackage.endDate ? new Date(workPackage.endDate) : null;
    if (!endDate || !startDate) return;

    if (startDate > endDate) {
      const newStartDate = subWeeks(endDate, 1);
      const updatedPackage: WorkPackageType = {
        ...workPackage,
        startDate: newStartDate.toDateString(),
      };
      dispatch(updateWorkPackage(updatedPackage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workPackage.endDate]);

  return (
    <>
      <FormElement.Horizontal>
        <StyledMediumField>
          <FormElement.Control>
            <FormElement.Label>
              Package name
              <FormElement.Input
                className="packageNameInput"
                name={`workPackages.${index}.name`}
                onChange={(e) => {
                  const updatedPackage = {
                    ...workPackage,
                    name: e.target.value,
                  };
                  dispatch(updateWorkPackage(updatedPackage));
                }}
                value={workPackage.name}
              />
            </FormElement.Label>
          </FormElement.Control>
        </StyledMediumField>

        <StyledSmallField>
          <FormElement.Control>
            <FormElement.Label>
              Start date
              <FormElement.Datepicker
                name={`workPackages.${index}.startDate`}
                onChange={(val) => {
                  if (val) {
                    const updatedPackage: WorkPackageType = {
                      ...workPackage,
                      startDate: val,
                    };
                    dispatch(updateWorkPackage(updatedPackage));
                  }
                }}
                startDate={workPackage.startDate}
              />
            </FormElement.Label>
          </FormElement.Control>
        </StyledSmallField>

        <StyledSmallField>
          <FormElement.Control>
            <FormElement.Label>
              End date
              <FormElement.Datepicker
                name={`workPackages.${index}.endDate`}
                onChange={(val) => {
                  if (val) {
                    let updatedPackage: WorkPackageType = {
                      ...workPackage,
                      endDate: val,
                    };
                    dispatch(updateWorkPackage(updatedPackage));
                  }
                }}
                startDate={workPackage?.endDate}
              />
            </FormElement.Label>
          </FormElement.Control>
        </StyledSmallField>

        <StyledSmallField>
          <FormElement.Control>
            <FormElement.Label>
              Duration (weeks)
              <FormElement.Input
                name={`workPackages.${index}.duration`}
                disabled={true}
                value={getDuration}
              />
            </FormElement.Label>
          </FormElement.Control>
        </StyledSmallField>
        <StyledDeleteIcon
          onClick={() => {
            removePackageHandler(workPackage);
          }}
        >
          <Elements.Tooltip text={'Delete package'}>
            <Icon.X />
          </Elements.Tooltip>
        </StyledDeleteIcon>
      </FormElement.Horizontal>

      <WorkPackageNotifications workPackage={workPackage} />

      <WorkPackageDetails workPackageIndex={index} workPackage={workPackage} />

      <Modal.Confirm
        header={'Delete package'}
        text={'All data within the package will be lost.'}
        confirmText={'Delete package'}
        toggle={confirmRemovePackageModal.toggle}
        confirm={() => {
          deleteWorkPackage();
        }}
        isShown={confirmRemovePackageModal.isShown}
      />
    </>
  );
};

export default WorkPackageContainer;

const StyledMediumField = styled.div`
  max-width: 300px;
`;

const StyledSmallField = styled.div`
  max-width: 200px;
`;
