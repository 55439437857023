import Header from './PanelHeader';
import Container from './PanelContainer';
import Subheader from './PanelSubheader';
import Body from './PanelBody';
import Wrapper from './PanelWrapper';

const Panel = {
  Wrapper,
  Header,
  Subheader,
  Container,
  Body,
};

export default Panel;
