import PrimaryButton from './PrimaryButton';
import DangerButton from './DangerButton';
import LinkButton from './LinkButton';
import WhiteButton from './WhiteButton';

const Button = {
    Primary : PrimaryButton,
    Danger: DangerButton,
    Link: LinkButton,
    White: WhiteButton
};

export default Button;