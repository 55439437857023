import { FC, useEffect } from 'react';
import styled from 'styled-components';
import Button from '../../../components/shared/Button';
import { IModalProps } from '../../../components/shared/Modal/ModalContainer';
import { StyledModal, Content, Backdrop, Wrapper } from './modal.style';
import FocusLock from 'react-focus-lock';
import React from 'react';
import ReactDOM from 'react-dom';

interface IConfirmModal extends IModalProps{
    confirm: ()=>void,
    header: string,
    text: string,
    confirmText: string
}

const confirmRoot = document.querySelector('#confirm');

const ConfirmModal: FC<IConfirmModal> = ({isShown, toggle, confirm, header, text, confirmText})=>{

    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (!isShown){ return; }
            event.preventDefault();
            const key = event.key || event.keyCode;
            if ((key === 'Escape' || key === 'Esc' || key === 27)) {
                toggle();
            }
            if ((key === 'Enter' || key === 13)) {
                confirm();
            }
        };
        isShown
          ? (document.body.style.overflow = 'hidden')
          : (document.body.style.overflow = 'unset');
        document.addEventListener('keydown', onKeyDown, false);
        return () => {
          document.removeEventListener('keydown', onKeyDown, false);
        };
    }, [isShown, toggle]);

    const ConfirmModalContainer = (
            <React.Fragment>
                <Backdrop $zIndex={2} onClick={toggle} />
                <FocusLock>
                    <Wrapper
                    $zIndex={2}
                    aria-modal
                    tabIndex={-1}
                    role="dialog"
                    >
                    <StyledModal>
                        <Content>
                            <StyledConfirmBody>
                                <h3>{header}</h3>
                                <p>{text}</p>
                                <StyledButtonContainer>
                                    <Button.White onClick={toggle}>Cancel</Button.White>
                                    <Button.Danger onClick={confirm}>{confirmText}</Button.Danger>
                                </StyledButtonContainer>
                            </StyledConfirmBody>
                        </Content>
                    </StyledModal>
                    </Wrapper>
                </FocusLock>
            </React.Fragment>
    );

    return confirmRoot && isShown ? ReactDOM.createPortal(ConfirmModalContainer, confirmRoot) : null;
};

const StyledConfirmBody = styled.div`
    width: 480px;
    max-width: 100vw;
    padding: ${({theme})=>theme.spacing[3]} ${({theme})=>theme.spacing[3]} ${({theme})=>theme.spacing[2]} ${({theme})=>theme.spacing[3]};
    > h3{
        margin-top: 0;
    }
    >p{
        color: ${({theme})=>theme.palette.gray50};
    }
`;

const StyledButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    >*{
        margin-left: ${({theme})=>theme.spacing[1]};
        margin-bottom: ${({theme})=>theme.spacing[1]};
    }
`;

export default ConfirmModal;