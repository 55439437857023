import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../components/shared/Button';
import Elements from '../../components/shared/Elements';
import Panel from '../../components/shared/Panel';
import { listProjectsThunk } from '../../reducers/projectList/listProjects';
import { RootState, useAppDispatch } from '../../store';
import ProjectControlList from './ProjectControlList';

const ProjectControlPage = () => {
  const dispatch = useAppDispatch();
  const projects = useSelector((state: RootState) => state.projects.projects);
  const projectsStatus = useSelector(
    (state: RootState) => state.projects.projectsStatus
  );

  useEffect(() => {
    async function getProjectList() {
      dispatch(listProjectsThunk());
    }
    getProjectList();
  }, [dispatch]);

  return (
    <Panel.Wrapper>
      <Panel.Container fullScreen={true} flexDirection="column">
        <Panel.Header>
          <div>
            <h1>Project Control </h1>
          </div>

          <Elements.Link to="new-project">
            <Button.Primary>Add new</Button.Primary>
          </Elements.Link>
        </Panel.Header>
        {/* {projectsStatus === 'loading' ? (
          <p>Loading</p>
        ) : (
          <ProjectControlList projects={projects} />
        )} */}
      </Panel.Container>
    </Panel.Wrapper>
  );
};

export default ProjectControlPage;
