import { createSlice } from '@reduxjs/toolkit';
import loadingState from '../types/loadingState.type';
import { employeeListType } from '../types/employeeList.type';
import { listEmployeesThunk } from './employeeList/listEmployees';

export interface EmployeesState {
  employees: employeeListType;
  employeesStatus: loadingState;
}

const initialState: EmployeesState = {
  employees: [],
  employeesStatus: 'loading'
};

export const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listEmployeesThunk.pending, (state, action) => {
        state.employeesStatus = 'loading';
      })
      .addCase(listEmployeesThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.employeesStatus = 'succeeded';
          let employees = action.payload.slice(); // Create a new copy of the array
          employees.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
          state.employees = employees;
        }
      })
      .addCase(listEmployeesThunk.rejected, (state, action) => {
        state.employeesStatus = 'failed';
      });
  }
});

export default employeesSlice.reducer;
