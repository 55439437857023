import { lighten } from 'polished';
import styled from 'styled-components';

interface IInput{
    hasError?: boolean
}

const Input = styled.input<IInput>`
    padding: 0.5em 0.7em;
    width: 100%;
    border: 1px solid ${({theme, hasError})=>hasError ? lighten(0.1, theme.palette.danger) : theme.palette.gray};
    border-radius: 2px;
    color: ${({theme, hasError})=>hasError ? theme.palette.danger : theme.text.default};
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.4;
    outline: none;
    &:focus{
        outline: 1px solid ${({theme})=>theme.palette.primary};
        outline-offset: -1px;
        color: ${({theme})=>theme.text.default};
    }
    &:disabled{
        background-color: ${({theme})=>theme.palette.gray97};;
    }
`;

export default Input;