import React, { useEffect } from 'react';
import MainRouting from './routing/MainRouting';
import { withAuthenticator } from '@aws-amplify/ui-react';
// import '@aws-amplify/ui-react/styles.css';
import  {Auth} from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { refreshLogin } from './reducers/userReducer';
import { Toaster } from 'react-hot-toast';
import { useTheme } from 'styled-components';

function App() {

  const dispatch = useDispatch();

  const theme = useTheme();

  useEffect(()=>{
    const refreshAuthStatus = async ()=>{
      let response = {
        username: null,
        loggedIn: false
      };
      try {     
        let user = await Auth.currentAuthenticatedUser(); 
        response.username = user.username;
        response.loggedIn = true;
        dispatch(refreshLogin(response));
      } catch(e) {
        dispatch(refreshLogin(response));
      }
    };
    refreshAuthStatus();
  }, [dispatch]);

  return (
    <>
      <MainRouting />
      <Toaster toastOptions={{
        className: '',
        style: {
          border: `1px solid ${theme.palette.danger}`,
          backgroundColor: `${theme.background.danger}`,
          padding: `${theme.spacing[1]} ${theme.spacing[2]}`,
          color: `${theme.text.default}`,
        },
      }} />
    </>
  );
}
export default App;
// export default withAuthenticator(App);
