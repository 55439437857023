import { lighten } from "polished";
import { ChangeEvent } from "react";
import styled from "styled-components";
import checkImage from "../../../assets/icons/check_white.svg";

const HiddenCheckbox = styled.input.attrs({type: 'checkbox'})`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

const StyledCheckbox = styled.div<ICheckbox>`
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 0;
  background-color: ${({checked, theme}) => checked ? theme.palette.primary : theme.background.secondary};
  background-image: ${({checked}) => checked ? `url(${checkImage})` : 'none'};
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid ${({checked, theme}) => checked ? theme.palette.primary : theme.palette.gray};
  border-radius: 3px;
  margin-right: 8px;
  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    outline-offset: 2px;
    outline: 2px solid ${({theme})=>theme.palette.primary};
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

interface ICheckbox{
    checked: boolean,
    onChange?: (e: ChangeEvent<HTMLInputElement>)=>void
}

const Checkbox = ({checked, ...props}: ICheckbox)=>{

    return (
        <CheckboxContainer>
            <HiddenCheckbox checked={checked} {...props} />
            <StyledCheckbox checked={checked} />
        </CheckboxContainer>    
    );
}

export default Checkbox;