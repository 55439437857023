import styled from 'styled-components';
import elementSize from '../../../types/elementSize';

interface IStyledIcon {
    readonly size?: elementSize,
    readonly color?: string
}

const StyledIcon = styled.div<IStyledIcon>`
    width: 20px;
    height: 20px;
`;

export default StyledIcon;
