import { Formik } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../../../components/shared/Button';
import FormElement from '../../../components/shared/FormElement';
import Panel from '../../../components/shared/Panel';
import * as Yup from 'yup';
import { API } from 'aws-amplify';
import { createEmployee } from '../../../graphql/mutations';
import { CreateEmployeeInput, CreateEmployeeMutation, Employee } from '../../../API';
import { useNavigate } from 'react-router-dom';
import IAWSErrorResponse from '../../../types/AWSError';

const NewEmployee = ()=>{
    const initialValues = {
        employeeName: '',
        employeeDescription: ''
    };

    let navigate = useNavigate();
    
    const [submitLoading, setSubmitLoading ]= useState(false);

    const validationSchema = Yup.object().shape({
        employeeName: Yup.string()
            .required('Employee name is required')
            .max(100, 'Must be less than 100 characters')
        ,
        employeeDescription: Yup.string()
            .max(500, 'Must be less than 500 characters')
    });

    const goToEmployee= (employeeId: Employee['id'] | undefined) =>{
        if (employeeId){
            navigate(`/employees/${employeeId}`);
        }
    };

    const [globalError, setGlobalError] = useState<IAWSErrorResponse | null>(null);

    const handleSubmit = async (values: { employeeName: string; employeeDescription?: string; })=>{
        setGlobalError(null);
        setSubmitLoading(true);
        const createEmployeeVariables: CreateEmployeeInput = {
            name: values.employeeName,
            description: values.employeeDescription
        };
        try{
            const apiData = (await API.graphql({ query: createEmployee, variables: {input: createEmployeeVariables} })) as { data: CreateEmployeeMutation };    
            const newEmployee = apiData.data.createEmployee;
            goToEmployee(newEmployee?.id);
        }
        catch(e){
            setSubmitLoading(false);
            const error = e as IAWSErrorResponse;
            setGlobalError(error);
        }
    };

    return (
        <Panel.Container fullScreen={true} 
            flexDirection='column'
        >
            <Panel.Header style={
                {
                    justifyContent: 'center'
                }
            }>
                <h1>New Employee</h1>
            </Panel.Header>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                >
                {({handleChange, values, errors, touched, handleSubmit, handleBlur}) => (
                    <StyledFormContainer autoComplete="off" onSubmit={handleSubmit}>
                        <FormElement.Control>
                            <FormElement.Label>
                            Employee name
                                <FormElement.Input 
                                    name="employeeName" 
                                    hasError={!!(touched.employeeName && errors.employeeName)}
                                    onBlur={handleBlur}
                                    onChange={handleChange} 
                                    value={values.employeeName} 
                                />

                            <FormElement.ErrorMessage show={!!(touched.employeeName && errors.employeeName)} message={errors.employeeName} />
                            </FormElement.Label>
                        </FormElement.Control>

                        <FormElement.Control>
                            <FormElement.Label>
                                Employee description
                                <FormElement.TextArea 
                                    name="employeeDescription" 
                                    hasError={!!(touched.employeeDescription && errors.employeeDescription)}
                                    onBlur={handleBlur}
                                    onChange={handleChange} 
                                    value={values.employeeDescription} 
                                />

                            <FormElement.ErrorMessage show={!!(touched.employeeDescription && errors.employeeDescription)} message={errors.employeeDescription} />
                            </FormElement.Label>
                        </FormElement.Control>
                        
                        {globalError &&
                            <FormElement.Control>
                                <FormElement.AWSErrorMessage response={globalError} />
                            </FormElement.Control>
                        }

                        <StyledLoginButtonContainer>
                            <Button.Primary loading={submitLoading} type="submit" >Create employee</Button.Primary>
                        </StyledLoginButtonContainer>

                    </StyledFormContainer>
                )}
            </Formik>
        </Panel.Container>
    );
};

const StyledFormContainer = styled.form`
    margin-top: ${({theme})=>theme.spacing[1]};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledLoginButtonContainer = styled.div`
    margin-top: ${({theme})=>theme.spacing[2]};
    
`;

export default NewEmployee;

