import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { WorkPackageType } from '../../../../types/workPackage.type';
import { calculatePackageSummary, calculatePackageTotalDays, formatCurrency } from '../../workPackageHelper';
import { RolesTotalsType } from './PackageSummary';
import { StyledHeader, StyledPackageSummaryTooltip, StyledPackageSummaryTooltipContainer, StyledSummary, StyledTable } from './packageSummary.styled';
import PackageSummaryTooltipRole from './PackageSummaryTooltipRole';

interface IPackageSummaryTooltip{
    workPackage: WorkPackageType,
    roleTotals: RolesTotalsType
};

const PackageSummaryTooltip = ({workPackage, roleTotals}: IPackageSummaryTooltip): JSX.Element=>{

    const defaultRoles = useSelector((state: RootState) => state.currentProject.defaultRoles);
    const packageSummaryCalculation = useMemo(()=>calculatePackageSummary(workPackage, defaultRoles), [workPackage, defaultRoles]);
    const packageTotalDaysCalculation = useMemo(()=>calculatePackageTotalDays(workPackage), [workPackage]);
    
    return (
           <StyledPackageSummaryTooltip>
               <StyledPackageSummaryTooltipContainer>
                   <StyledTable>
                        <StyledHeader>
                            ROLE
                        </StyledHeader>

                        <StyledHeader>
                            DAYS
                        </StyledHeader>

                        <StyledHeader>
                            DAILY RATE
                        </StyledHeader>
                        
                        <StyledHeader>
                            EST. COST
                        </StyledHeader>

                        {roleTotals.roles?.map(role=>(
                            <PackageSummaryTooltipRole key={role.role} role={role} defaultRoles={defaultRoles} />
                        ))}

                        <StyledSummary>
                            Package total
                        </StyledSummary>

                        <StyledSummary>
                            {packageTotalDaysCalculation}
                        </StyledSummary>

                        <StyledSummary>
                        </StyledSummary>

                        <StyledSummary>
                            {formatCurrency(packageSummaryCalculation)}
                        </StyledSummary>

                   </StyledTable>
               </StyledPackageSummaryTooltipContainer>
           </StyledPackageSummaryTooltip>
        );
};


export default PackageSummaryTooltip;