import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultRoleType } from '../types/defaultRole.type';
import loadingState from '../types/loadingState.type';
import { currentProjectType } from '../types/project.type';
import { WorkPackageRoleType, WorkPackageEmployeeType, WorkPackageType } from '../types/workPackage.type';
import { addPackageRoleThunk } from './currentProject/addPackageRole';
import { addWorkPackageThunk } from './currentProject/addWorkPackage';
import { deletePackageRoleThunk } from './currentProject/deletePackageRole';
import { deleteProjectThunk } from './currentProject/deleteProject';
import { deleteWorkPackageThunk } from './currentProject/deleteWorkPackage';
import { getProjectThunk } from './currentProject/getProject';
import { listDefaultRolesThunk } from './currentProject/listDefaultRoles';

export interface ProjectState {
    currentProject: currentProjectType | null,
    projectStatus: loadingState,
    defaultRoles: defaultRoleType[],
};

const initialState: ProjectState = {
    currentProject: null,
    defaultRoles: [],
    projectStatus: 'loading'
};

export const currectProjectSlice = createSlice({
  name: 'currentProject',
  initialState,
  reducers: {
    removeWorkPackage: (state, action: PayloadAction<WorkPackageType>)=>{
      const removedPackage = action.payload;
      if (removedPackage && state.currentProject){
        state.currentProject.workPackages.items = state.currentProject.workPackages.items?.filter(w=>w.id !== removedPackage.id);
      }
    },
    addWorkPackage: (state, action: PayloadAction<WorkPackageType>)=>{
      const newWorkPackage = action.payload;
      if (newWorkPackage && state.currentProject){
        state.currentProject.workPackages.items?.push(newWorkPackage);
      }
    },
    updateWorkPackage: (state, action: PayloadAction<WorkPackageType>)=>{
      let updatedWorkPackage = action.payload;
      if (updatedWorkPackage && state?.currentProject?.workPackages){
        state.currentProject.workPackages.items = state.currentProject.workPackages.items?.map(w=>{
          if (w.id === updatedWorkPackage.id){
            return updatedWorkPackage;
          }
          return w;
        });
      }
    },
    updateWorkPackageRole: (state, action: PayloadAction<WorkPackageRoleType>)=>{
      const updatedRole = action.payload;
      if (updatedRole && state.currentProject){
        const workPackage = state.currentProject.workPackages.items?.find(p=>p.id === updatedRole.workPackageRolesId);
        if (workPackage?.roles){
          workPackage.roles.items = workPackage?.roles?.items?.map(r=>{
            if (r.id === updatedRole.id) return updatedRole;
            return r;
          });
        }
      }
    },
    updateWorkPackageEmployee: (state, action: PayloadAction<WorkPackageEmployeeType>)=>{
      const updatedEmployee = action.payload;
      if (updatedEmployee && state.currentProject){
        const workPackage = state.currentProject.workPackages.items?.find(p=>p.id === updatedEmployee.workPackageEmployeesId);
        if (workPackage?.employees){
          workPackage.employees.items = workPackage?.employees?.items?.map(r=>{
            if (r.id === updatedEmployee.id) return updatedEmployee;
            return r;
          });
        }
      }
    },
  
    
  },
  extraReducers(builder) {
    builder
      .addCase(deleteProjectThunk.fulfilled, (state, action) => {
        if (action.payload){
          state.currentProject = null;
        }
      })
      .addCase(addPackageRoleThunk.fulfilled, (state, action) => {
        const newPackageRole = action.payload;

        if (newPackageRole && state.currentProject){
          const workPackage = state.currentProject.workPackages.items?.find(p=>p.id === newPackageRole.workPackageRolesId);

          if (workPackage && !workPackage?.roles?.items){
            workPackage.roles = {
              items: []
            };
          }

          workPackage?.roles?.items?.push(newPackageRole);
        }
      })
      .addCase(deleteWorkPackageThunk.fulfilled, (state, action) =>{
        const deletedPackage = action.payload;
        if (deletedPackage && state.currentProject){
          state.currentProject.workPackages.items = state.currentProject?.workPackages.items?.filter(w=>w.id !== deletedPackage.id);
        }
      })
      .addCase(listDefaultRolesThunk.fulfilled, (state, action) =>{
        if (action.payload){
            state.defaultRoles = action.payload.items;
          }
      })
      .addCase(deletePackageRoleThunk.fulfilled, (state, action) =>{
        const deletedRole = action.payload;
        if (deletedRole && state.currentProject){
          const workPackage = state.currentProject.workPackages.items?.find(p=>p.id === deletedRole.workPackageRolesId);
          if (workPackage?.roles){
            workPackage.roles.items = workPackage?.roles?.items?.filter(r=>r.id !== deletedRole.id);
          }
        }
      })

      .addCase(addWorkPackageThunk.fulfilled, (state, action) =>{
        if (action.payload){
            const payload = action.payload as WorkPackageType;
            const newWorkPackage: WorkPackageType = {
                ...payload,
                startDate: payload?.startDate || '',
                endDate: payload?.endDate || '',
            };
            state.currentProject?.workPackages?.items?.push(newWorkPackage);
          }
      })

      .addCase(getProjectThunk.pending, (state, action) => {
        state.projectStatus = 'loading';
      })

      .addCase(getProjectThunk.fulfilled, (state, action) =>{
        if (action.payload){
          state.currentProject = action.payload;
          state.projectStatus = 'succeeded';
        }else{
          state.currentProject = null;
          state.projectStatus = 'failed';
        }
      });
  }
});

export default currectProjectSlice.reducer;
export const { updateWorkPackage, addWorkPackage, removeWorkPackage, updateWorkPackageRole, updateWorkPackageEmployee } = currectProjectSlice.actions;