import styled from "styled-components";

interface IControl{
    readonly fullWidth?: boolean;
}

const Control = styled.div<IControl>`
    display: block;
    width: ${({fullWidth})=>fullWidth ? "100%" : "330px"};
    max-width: 100%;
    & + &{
        margin-top: 1em;
    }
`;

export default Control;