import { Formik } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../../components/shared/Button';
import FormElement from '../../components/shared/FormElement';
import Panel from '../../components/shared/Panel';
import * as Yup from 'yup';
import { API } from 'aws-amplify';
import { createProject } from '../../graphql/mutations';
import { CreateProjectInput, CreateProjectMutation, Project } from '../../API';
import { useNavigate } from 'react-router-dom';
import IAWSErrorResponse from '../../types/AWSError';


const NewProject = ()=>{
    const initialValues = {
        projectName: '',
        projectDescription: ''
    };

    let navigate = useNavigate();
    
    const [submitLoading, setSubmitLoading ]= useState(false);

    const validationSchema = Yup.object().shape({
        projectName: Yup.string()
            .required('Project name is required')
            .max(100, 'Must be less than 100 characters')
        ,
        projectDescription: Yup.string()
            .max(500, 'Must be less than 500 characters')
    });

    const goToProject = (projectId: Project['id'] | undefined) =>{
        if (projectId){
            navigate(`/projects/${projectId}`);
        }
    };

    const [globalError, setGlobalError] = useState<IAWSErrorResponse | null>(null);

    const handleSubmit = async (values: { projectName: string; projectDescription?: string; })=>{
        setGlobalError(null);
        setSubmitLoading(true);
        const createProjectVariables: CreateProjectInput = {
            name: values.projectName,
            description: values.projectDescription
        };
        try{
            const apiData = (await API.graphql({ query: createProject, variables: {input: createProjectVariables} })) as { data: CreateProjectMutation };    
            const newProject = apiData.data.createProject;
        
            goToProject(newProject?.id);
        }
        catch(e){
            setSubmitLoading(false);
            const error = e as IAWSErrorResponse;
            setGlobalError(error);
        }
    };

    return (
        <Panel.Container fullScreen={true} 
            flexDirection='column'
        >
            <Panel.Header style={
                {
                    justifyContent: 'center'
                }
            }>
                <h1>New Project</h1>
            </Panel.Header>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                >
                {({handleChange, values, errors, touched, handleSubmit, handleBlur}) => (
                    <StyledFormContainer autoComplete="off" onSubmit={handleSubmit}>
                        <FormElement.Control>
                            <FormElement.Label>
                                Project name
                                <FormElement.Input 
                                    name="projectName" 
                                    hasError={!!(touched.projectName && errors.projectName)}
                                    onBlur={handleBlur}
                                    onChange={handleChange} 
                                    value={values.projectName} 
                                />

                            <FormElement.ErrorMessage show={!!(touched.projectName && errors.projectName)} message={errors.projectName} />
                            </FormElement.Label>
                        </FormElement.Control>

                        <FormElement.Control>
                            <FormElement.Label>
                                Project description
                                <FormElement.TextArea 
                                    name="projectDescription" 
                                    hasError={!!(touched.projectDescription && errors.projectDescription)}
                                    onBlur={handleBlur}
                                    onChange={handleChange} 
                                    value={values.projectDescription} 
                                />

                            <FormElement.ErrorMessage show={!!(touched.projectDescription && errors.projectDescription)} message={errors.projectDescription} />
                            </FormElement.Label>
                        </FormElement.Control>
                        
                        {globalError &&
                            <FormElement.Control>
                                <FormElement.AWSErrorMessage response={globalError} />
                            </FormElement.Control>
                        }

                        <StyledLoginButtonContainer>
                            <Button.Primary loading={submitLoading} type="submit" >Create project</Button.Primary>
                        </StyledLoginButtonContainer>

                    </StyledFormContainer>
                )}
            </Formik>
        </Panel.Container>
    );
};

const StyledFormContainer = styled.form`
    margin-top: ${({theme})=>theme.spacing[1]};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledLoginButtonContainer = styled.div`
    margin-top: ${({theme})=>theme.spacing[2]};
    
`;

export default NewProject;

