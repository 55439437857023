import { FC } from 'react';
import styled from 'styled-components';
import Hr from '../../shared/Elements/Hr';
import { useSelector } from 'react-redux';
import { logoutUser } from '../../../reducers/user/logoutUser';
import { RootState, useAppDispatch } from '../../../store';

const HeaderUser: FC = () => {
  const dispatch = useAppDispatch();
  const username = useSelector((state: RootState) => state.user.username);

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  return (
    <StyledHeaderUserContainer>
      <StyledHeaderUser>{username}</StyledHeaderUser>
      <div>
        <StyledLogout onClick={handleLogout}>Log out</StyledLogout>
      </div>
    </StyledHeaderUserContainer>
  );
};

const StyledLogout = styled.span`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary};
  margin: 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledHeaderUser = styled.div`
  margin-right: ${({ theme }) => theme.spacing[2]};
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.text.white};
`;

const StyledHeaderUserContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default HeaderUser;
