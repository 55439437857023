import { darken } from 'polished';
import styled, { css } from 'styled-components';
import Button, { IButtonStyled } from './buttonStyling';

const StyledPrimaryButton = styled(Button)`
    ${({invert})=>invert ? PrimaryInverted : PrimaryButton};
`;

const PrimaryButton = css<IButtonStyled>`
    background-color: ${({theme})=>theme.palette.primary};
    color: white;
    &:focus{
        outline: 3px solid ${({theme})=>theme.palette.primary};
        background-color: ${({theme})=>theme.palette.primary};
    }
    &:hover{
        background-color: ${({theme})=>darken(0.1,theme.palette.primary)};
    }
`;

const PrimaryInverted = css<IButtonStyled>`
    background-color: transparent;
    border: 1px solid ${({theme})=>theme.palette.primary};
    color: ${({theme})=>theme.palette.primary};
    &:focus{
        outline: 3px solid ${({theme})=>theme.palette.primary};
        background-color: ${({theme})=>theme.palette.primary};
    }
    &:hover{
        background-color: ${({theme})=>darken(0.1,theme.palette.primary)};
        color: ${({theme})=>theme.palette.white};
    }
`;

export default StyledPrimaryButton;