import styled, { useTheme } from 'styled-components';
import { NotificationBlock, NotificationBlockContainer } from '../../../components/shared/Elements/NotificationBlock';
import Icon from '../../../components/shared/Icon';

const StartDateBeforeTodayNotification = ()=>{
    const theme = useTheme();

    return(
        <StyledNotificationContainer>
            <NotificationBlock status='primary'>
                <NotificationBlockContainer>
                    <Icon.Info color={theme.palette.primary} />
                    <div>
                        Your start date is in the past.
                    </div>
                </NotificationBlockContainer>
            </NotificationBlock>
        </StyledNotificationContainer>
    );
};

const StyledNotificationContainer = styled.div`
    margin-top: ${({theme})=>theme.spacing[2]};
`;

export default StartDateBeforeTodayNotification;