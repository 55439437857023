import styled from 'styled-components';
import { defaultRoleType } from '../../../../types/defaultRole.type';
import { formatCurrency } from '../../workPackageHelper';
import { TotalsType } from './PackageTotals';
import PackageTotalsRole from './PackageTotalsRole';

interface IPackageTotalsTooltip{
    totals: TotalsType,
    defaultRoles: defaultRoleType[]
}

const PackageTotalsTooltip = ({totals, defaultRoles}: IPackageTotalsTooltip)=>{
    return (
            <StyledPackageSummaryTooltipContainer>
                <StyledTable>
                        <StyledHeader>
                            TOTAL SUMMARY
                        </StyledHeader>

                        <StyledHeader>
                            DAYS
                        </StyledHeader>
                        
                        <StyledHeader>
                            EST. COST
                        </StyledHeader>

                        <StyledSummary>
                            All roles
                        </StyledSummary>

                        <StyledSummary>
                            {totals.days}
                        </StyledSummary>

                        <StyledSummary>
                            {formatCurrency(totals.cost)}
                        </StyledSummary>

                        {totals.roles.map(role=>(
                            <PackageTotalsRole key={role.role} defaultRoles={defaultRoles} role={role} />
                        ))}

                </StyledTable>
                
            </StyledPackageSummaryTooltipContainer>
    );
};


const StyledHeader = styled.div`
    font-weight: 400;
    color: ${({theme})=>theme.text.secondary};
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid ${({theme})=>theme.elements.line};
    align-items: center;
    letter-spacing: 1px;
    display: flex;
    font-size: 1.2rem;
`;

const StyledSummary = styled.div`
    color: #4B4B4B;
    font-size: 1.4rem;
    font-weight: 700;
`;

const StyledTable = styled.div`
    display: grid;
    grid-template-columns: 1.4fr 1fr 1fr;
    >:nth-child(3n+1){
        justify-content: flex-start;
    }
    >*{
        justify-content: flex-end;
        display: flex;
}
    >*:not(${StyledHeader}){
        font-size: 1.4rem;
        line-height: 2.0rem;
        padding-top: 6px;
        padding-bottom: 6px;

    }
`;

const StyledPackageSummaryTooltipContainer  = styled.div`
    display: block;
    width: 100%;
    min-width: 350px;
    height: 100%;
    background-color: ${({theme})=>theme.background.secondary};
    padding: 0 ${({theme})=>theme.spacing[2]} ${({theme})=>theme.spacing[1]};
    z-index: 2;
    position: relative;
`;

export default PackageTotalsTooltip;