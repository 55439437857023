import Badge from './Badge';
import Hr from './Hr';
import HrText from './HrText';
import Link from './Link';
import {
  NotificationBlock,
  NotificationBlockContainer,
} from './NotificationBlock';
import Spinner from './Spinner';
import Tooltip from './Tooltip';
import Status from './Status';
import NoResults from './NoResults';

const Elements = {
  Hr,
  HrText,
  Badge,
  Spinner,
  Link,
  Tooltip,
  NotificationBlock,
  NotificationBlockContainer,
  Status,
  NoResults,
};

export default Elements;
