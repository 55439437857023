import styled from 'styled-components';

const TableStyled = styled.table`
    border: none;
    width: 100%;
    border-collapse: collapse; 
`;

export default TableStyled;

