import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Header from '../components/layout/Header';
import Logo from '../components/shared/Logo/Logo';
import Sidebar from '../components/shared/Sidebar';
import SidebarRouting from '../components/SidebarRouting/SidebarRouting';
import SidebarUser from '../components/SidebarUser/SidebarUser';
import Panel from '../components/shared/Panel';

const StyledLayout = styled.div`
  /* display: grid;
  grid-template-columns: 180px 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'sidebar main'; */
  background-color: none;
`;

const PageContainer = styled.div`
  /* grid-area: main; */
  width: 100%;
  height: 100%;
  padding-top: 70px;
`;

const MainLayout = () => {
  return (
    <StyledLayout>
      {/* <Sidebar.Container>
                <Logo />
                <SidebarRouting />
                <SidebarUser />
            </Sidebar.Container> */}
      <Header.Wrapper>
        <Panel.Container height="100%" alignItems="center">
          <Logo />
          <Header.Navigation />
          <Header.User />
        </Panel.Container>
      </Header.Wrapper>
      <PageContainer>
        <Outlet />
      </PageContainer>
    </StyledLayout>
  );
};

export default MainLayout;
