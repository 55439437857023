import styled from 'styled-components';
import StyledIcon from '../../../components/shared/Icon/StyledIcon';

export const StyledDeleteIcon = styled.div`
    cursor: pointer;
    align-self: flex-end;
    margin: 0;
    ${StyledIcon}{
        padding: 20px;
    }
`;

export const StyledDeleteRole = styled.div`
    margin-left: auto;
    cursor: pointer;
    visibility: hidden;
`;


export const StyledAddAnotherContainer = styled.div`
    margin-top: ${({theme})=>theme.spacing[2]};
`;

export const StyledWorkPackageDetailsContainer = styled.div`
    margin-top: ${({theme})=>theme.spacing[2]};
    display: grid;
    grid-template-columns: 300px 1fr;

    & + *{
        margin-top: ${({theme})=>theme.spacing[4]};
    }

    & + ${StyledAddAnotherContainer}{
        margin-top: ${({theme})=>theme.spacing[3]};
    }
`;

export const StyledWorkPackageDetails = styled.div`
    
    width: 300px;
    padding-bottom: ${({theme})=>theme.spacing[1]};
    background-color: #FCFCFC;
    border: 1px solid ${({theme})=>theme.elements.line};
    border-radius: 2px;
    h4{
        margin: 0 0 ${({theme})=>theme.spacing[1]} 0;
        word-break: break-word;
    }
    &:hover{
        ${StyledDeleteRole}{
            visibility: visible;
        }
    }

`;

export const StyledDescription = styled.div`
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${({theme})=>theme.palette.gray50};
`;

export const StyledSaveButton = styled.div`
    margin-left: auto;
`;
