import React from 'react';
import styled from 'styled-components';

const LogoContainerStyled = styled.a`
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 100%;
  color: ${({ theme }) => theme.palette.white};
  margin-right: ${({ theme }) => theme.spacing[4]};
  text-decoration: none;

  span {
    color: ${({ theme }) => theme.palette.primary};
  }
`;

const Logo = () => {
  return (
    <LogoContainerStyled href="/">
      dige.<span>staff</span>
    </LogoContainerStyled>
  );
};

export { LogoContainerStyled };
export default Logo;
