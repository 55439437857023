import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import LoginPage from '../pages/LoginPage/LoginPage';
import NewProject from '../pages/NewProjectPage/NewProjectPage';
import People from '../pages/People';
import Projects from '../pages/ProjectListPage/ProjectListPage';
import StyleGuide from '../pages/StyleGuide';
import { RootState } from '../store';
import { useSelector } from 'react-redux';
import ProjectPage from '../pages/ProjectPage/ProjectPage';
import ProjectControlList from '../pages/ProjectControlPage/ProjectControlPage';
import Employees from '../pages/Employees/EmployeeListPage/EmployeeListPage';
import NewEmployee from '../pages/Employees/NewEmployeePage/NewEmployeePage';
import EmployeePage from '../pages/Employees/EmployeePage/EmployeePage';


const MainRouting = () => {
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  return (
    <BrowserRouter>
      {loggedIn ? (
        <Routes>
          <Route path="/*" element={<MainLayout />}>
            <Route index element={<Navigate replace to="projects" />} />

            <Route path="projects">
              <Route index element={<Projects />} />
              <Route path="list" element={<Projects />} />

              <Route path="new-project" element={<NewProject />} />
              <Route path=":id" element={<ProjectPage />} />
            </Route>
            <Route path="employees">
              <Route index element={<Employees />} />
              <Route path="list" element={<Employees />} />

              <Route path="new-employee" element={<NewEmployee />} />
              <Route path=":id" element={<EmployeePage />} />
            </Route>
            <Route path="people" element={<People />} />
            <Route path="style-guidelines" element={<StyleGuide />} />

            <Route path="project-control">
              <Route index element={<ProjectControlList />} />
              <Route path="list" element={<ProjectControlList />} />

              <Route path="new-project" element={<NewProject />} />
            </Route>
          </Route>
        </Routes>
      ) : (
        <LoginPage />
      )}
    </BrowserRouter>
  );
};

export default MainRouting;
