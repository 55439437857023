import { darken } from 'polished';
import styled from 'styled-components';
import StyledIcon from '../Icon/StyledIcon';

interface INotificationBlock{
    status: 'success' | 'warning' | 'danger' | 'default' | 'primary'
}

const NotificationBlock = styled.div<INotificationBlock>`
    background-color: ${({theme, status})=>theme.background[status]};
    color: ${({theme, status})=>darken(0.1,theme.palette[status])};
    border-radius: 2px;
    font-size: 1.4rem;
    line-height: 1.4em;
    padding: 1.0em 0.9em;
    small{
        font-size: 1.2rem;
    }
    > ${StyledIcon}{
        margin-right: ${({theme})=>theme.spacing[2]};
    }

    >ul{
        margin: 0;
        padding-left: 0.9em;
        li:not(:last-child){
            margin-bottom: 0.5em;
        }
    }
`;

const NotificationBlockContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    > ${StyledIcon}{
        margin-right: ${({theme})=>theme.spacing[2]};
    }
`;

export { NotificationBlock, NotificationBlockContainer };

