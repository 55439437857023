import styled from 'styled-components';

const TdStyled = styled.td`
  padding: 20px 16px;
  color: ${({ theme }) => theme.text.default};
  font-size: 1.4rem;
  font-weight: 400;
  text-align: left;
`;

export default TdStyled;
