import FullScreen from './FullScreen';
import Header from './Header';
import { CloseButton, Body } from './modal.style';
import { ModalContainer } from './ModalContainer';
import Confirm from './ConfirmModal';

const Modal = {
    FullScreen,
    ModalContainer,
    Confirm,
    Header,
    CloseButton,
    Body
};

export default Modal;
