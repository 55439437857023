import styled from 'styled-components';
import NoResultsIcon from '../../shared/Icon/NoResultsIcon';

const NoResults = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) => {
  return (
    <Wrapper>
      <NoResultsIcon />
      <p>{title}</p>
      <span>{subtitle}</span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 6rem 0;

  p {
    font-size: 2.4rem;
    font-weight: 500;
    margin: 0 0 1.2rem 0;
  }
  span {
    font-size: 1.4rem;
    color: #808080;
  }
`;
export default NoResults;
