import { IRoleOption } from './WorkPackages/workPackages.type';
import {
  eachWeekOfInterval,
  isWeekend,
  eachDayOfInterval,
  differenceInBusinessDays,
  differenceInDays,
  startOfDay,
} from 'date-fns';
import { v4 as uuid } from 'uuid';
import { store } from '../../store';
import { addWorkPackageThunk } from '../../reducers/currentProject/addWorkPackage';
import {
  WorkPackageRoleType,
  WorkPackageEmployeeType,
  WorkPackageType,
} from '../../types/workPackage.type';
import { updateWorkPackageThunk } from '../../reducers/currentProject/updateWorkPackage';
import { deleteWorkPackageThunk } from '../../reducers/currentProject/deleteWorkPackage';
import { defaultRoleType } from '../../types/defaultRole.type';
import { addPackageRoleThunk } from '../../reducers/currentProject/addPackageRole';
import { deletePackageRoleThunk } from '../../reducers/currentProject/deletePackageRole';
import { updatePackageRoleThunk } from '../../reducers/currentProject/updatePackageRole';
import { updatePackageEmployeeThunk } from '../../reducers/currentProject/updatePackageEmployee';


export const createEmptyPackage = () => {
  const today = startOfDay(new Date());
  const emptyPackage: WorkPackageType = {
    id: uuid(),
    name: '',
    duration: undefined,
    endDate: undefined,
    startDate: today.toISOString(),
    // roles: [createEmptyRole()]
  };
  return emptyPackage;
};

export const createEmptyRole = (
  defaultRoles: defaultRoleType[],
  workPackageRolesId: string
) => {
  const emptyRole: WorkPackageRoleType = {
    id: uuid(),
    // name: defaultRoles[0].name,
    rolePackagesId: defaultRoles[0].id,
    workPackageRolesId: workPackageRolesId,
    capacity: 100,
    employeePackagesId: null,
  };
  return emptyRole;
};

export const formatCurrency = (number: number) => {
  const numberString = number.toLocaleString();
  return '€' + numberString;
};

export const getRoleRate = (
  rolePackagesId: WorkPackageRoleType['rolePackagesId'],
  defaultRoles: defaultRoleType[]
) => {
  if (!defaultRoles?.length) {
    return 0;
  }
  const selectedRole = defaultRoles.find(
    (defaultRole) => defaultRole.id === rolePackagesId
  );
  return selectedRole?.defaultRate?.value || 0;
};

export const getRoleName = (
  rolePackagesId: WorkPackageRoleType['rolePackagesId'],
  defaultRoles: defaultRoleType[]
) => {
  if (!defaultRoles?.length) {
    return '';
  }
  const selectedRole = defaultRoles.find(
    (defaultRole) => defaultRole.id === rolePackagesId
  );
  return selectedRole?.name || '';
};

export const getEmployeeName = (
  employeePackagesId: WorkPackageEmployeeType['employeePackagesId'],
  employees: WorkPackageEmployeeType[]
) => {
  if (!employees?.length) {
    return '';
  }
  const selectedEmployee = employees.find(
    (employee) => employee.id === employeePackagesId
  );
  return selectedEmployee?.name || '';
};


export const calculatePackageTotalDays = (workPackage: WorkPackageType) => {
  if (!workPackage.startDate || !workPackage.endDate) {
    return 0;
  }

  let totalDays = 0;
  workPackage.roles?.items?.forEach((role) => {
    if (workPackage.startDate && workPackage.endDate && role.capacity) {
      const days = calculateWorkingDays(
        new Date(workPackage.startDate),
        new Date(workPackage.endDate),
        role.capacity
      );
      totalDays += days;
    }
  });
  return totalDays;
};

export const calculatePackageSummary = (
  workPackage: WorkPackageType,
  defaultRoles: defaultRoleType[]
): number => {
  if (!defaultRoles.length || !workPackage.startDate || !workPackage.endDate) {
    return 0;
  }
  let total = 0;
  workPackage.roles?.items?.forEach((role) => {
    let subtotal = 0;
    if (!workPackage.startDate || !workPackage.endDate || !role.capacity) {
      return 0;
    }
    const defaultRole = defaultRoles.find((d) => d.id === role.rolePackagesId);
    const days = calculateWorkingDays(
      new Date(workPackage.startDate),
      new Date(workPackage.endDate),
      role.capacity
    );
    if (!defaultRole?.defaultRate.value) {
      return 0;
    }
    subtotal = days * defaultRole?.defaultRate.value;
    total += subtotal;
  });
  return total;
};

export const calculateWorkingDays = (
  startDate: Date,
  endDate: Date,
  capacity: number
) => {
  if (startDate > endDate) {
    return 0;
  }

  // counting how many weeks in interval
  const allWeeksWithDates = getWeekPeriods(startDate, endDate);

  // calculating how many days of capacity can be used
  const allWeeksWithDuration = allWeeksWithDates.map((week) => {
    // counting all days of the week without weekends
    const maxWorkingDays = differenceInBusinessDays(
      week.endDate,
      week.startDate
    );
    // counting all days of the week with weekends
    const maxOvertimeDays = differenceInDays(week.endDate, week.startDate);
    // converting percent value to days per week (5 days = 100%, or 20% = 1 day)
    const dayCapacity = capacity / 20;
    //checking if user selected overtime over weekend
    if (dayCapacity > 5) {
      //checking if there is weekends in the interval
      if (maxOvertimeDays > maxWorkingDays) {
        // returning business days with extra days defined by capacity over 100%
        return maxWorkingDays + (dayCapacity - 5);
      }
      // returning regular working days
      return maxWorkingDays;
    }
    //checking if there is days of week required in capacity
    if (dayCapacity >= maxWorkingDays) {
      // returning maximum number of days if capacity is higher
      return maxWorkingDays;
    }
    // returning capacity of days if there is no other matching condition
    return dayCapacity;
  });
  // returning sum of week durations
  return allWeeksWithDuration.reduce((a, b) => a + b, 0);
};

export const roleOptions: IRoleOption[] = [
  { value: 'Consultant', label: 'Consultant' },
  { value: 'Manager', label: 'Manager' },
  { value: 'Partner', label: 'Partner' },
];

export interface ICapacityOption {
  value: number;
  label: string;
}

export const capacityOptions: ICapacityOption[] = [
  { value: 10, label: '10%' },
  { value: 20, label: '20%' },
  { value: 30, label: '30%' },
  { value: 40, label: '40%' },
  { value: 50, label: '50%' },
  { value: 60, label: '60%' },
  { value: 70, label: '70%' },
  { value: 80, label: '80%' },
  { value: 90, label: '90%' },
  { value: 100, label: '100%' },
  // { value: 120, label: '120%' },
  // { value: 140, label: '140%' },
];

// export const defaultPayrates = [
//     {role: IRoleType.Consultant, payrate: 1750},
//     {role: IRoleType.Consultant, payrate: 1950},
//     {role: IRoleType.Consultant, payrate: 2250},
// ];

export type WeekPeriod = {
  startDate: Date;
  endDate: Date;
  workingDays: Date[];
  days: Date[];
};

export const getWeekPeriods = (
  startDate: Date,
  endDate: Date
): WeekPeriod[] => {
  if (startDate > endDate) {
    return [];
  }
  // counting how many weeks in interval
  const allWeeks = eachWeekOfInterval(
    {
      start: startDate,
      end: endDate,
    },
    { weekStartsOn: 1 }
  );

  // assigning each week its start and end date (first and last weeks might have different times)
  const allWeeksWithDates: WeekPeriod[] = allWeeks.map(
    (weekInitialDay, index) => {
      let intervalStartDate = index === 0 ? startDate : weekInitialDay;
      let intervalEndDate =
        index + 1 === allWeeks.length ? endDate : allWeeks[index + 1];

      let allDays = eachDayOfInterval({
        start: intervalStartDate,
        end: intervalEndDate,
      });

      if (allDays.length > 1) {
        allDays.pop();
      }

      let workingDays = allDays.filter((day) => !isWeekend(day));

      let weekWithDuration: WeekPeriod = {
        days: allDays,
        workingDays: workingDays,
        startDate: intervalStartDate,
        endDate: intervalEndDate,
      };
      return weekWithDuration;
    }
  );
  return allWeeksWithDates;
};

export const getDaysPeriods = (startDate: Date, endDate: Date): Date[] => {
  if (startDate > endDate) {
    return [];
  }
  // counting how many weeks in interval
  const allDays = eachDayOfInterval({ start: startDate, end: endDate });
  return allDays;
};

export const createWorkPackageAPI = (
  workPackage: WorkPackageType,
  projectId: string
) => {
  const workPackageParameters = {
    ...workPackage,
    endDate: workPackage.endDate,
    projectId: projectId,
  };
  return store.dispatch(addWorkPackageThunk(workPackageParameters));
};

export const updateWorkPackageAPI = (workPackage: WorkPackageType) => {
  return store.dispatch(updateWorkPackageThunk({ ...workPackage }));
};

export const removeWorkPackageAPI = (workPackageId: WorkPackageType['id']) => {
  return store.dispatch(deleteWorkPackageThunk(workPackageId));
};

export const removeWorkPackageRoleAPI = (roleId: WorkPackageRoleType['id']) => {
  return store.dispatch(deletePackageRoleThunk(roleId));
};

export const updateWorkPackageRoleAPI = (
  workPackageRole: WorkPackageRoleType
) => {
  return store.dispatch(updatePackageRoleThunk({ ...workPackageRole }));
};

export const updateWorkPackageEmployeeAPI = (
  workPackageEmployee: WorkPackageEmployeeType
) => {
  return store.dispatch(updatePackageEmployeeThunk({ ...workPackageEmployee }));
};

export const addWorkPackageRoleAPI = (workPackageRole: WorkPackageRoleType) => {
  return store.dispatch(addPackageRoleThunk(workPackageRole));
};
