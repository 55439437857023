import { useMemo } from 'react';
import { defaultRoleType } from '../../../../types/defaultRole.type';
import { formatCurrency, getRoleName, getRoleRate } from '../../workPackageHelper';
import { RolesTotalsRoleType } from './PackageSummary';
import { StyledRole } from './packageSummary.styled';

interface IPackageSummaryTooltipRole{
    role: RolesTotalsRoleType,
    defaultRoles: defaultRoleType[]
};

const PackageSummaryTooltipRole = ({role, defaultRoles}: IPackageSummaryTooltipRole)=>{
    const roleName = useMemo(()=>getRoleName(role.role, defaultRoles), [role.role, defaultRoles]);    
    const roleRate = useMemo(()=>getRoleRate(role.role, defaultRoles), [role.role, defaultRoles]);

    return(
        <>
            <StyledRole>
                {roleName}
            </StyledRole>
            <StyledRole>
                {role.days}
            </StyledRole>
            <StyledRole>
                {formatCurrency(roleRate)}
            </StyledRole>
            <StyledRole>
                {formatCurrency(role.cost)}
            </StyledRole>
        </>
    );
};

export default PackageSummaryTooltipRole;