import { FC } from 'react';
import AWSResponseError from '../../../types/AWSError';
import { NotificationBlock } from '../Elements/NotificationBlock';

interface IAWSErrorMessage {
    response : AWSResponseError
};

const AWSErrorMessage : FC<IAWSErrorMessage> = ({response})=>(
    <>
    {response?.errors && response.errors.length === 1 && response.errors.map(error=>(
        <NotificationBlock status='danger'>
            {error.message}
        </NotificationBlock>
    ))}
    {response?.errors && response.errors.length > 1 && 
        <NotificationBlock status='danger'>
            <ul>
                {response.errors.map(error=>(
                <li>
                    {error.message}
                </li>
                ))}
            </ul>
        </NotificationBlock>
    }
    </>
); 

export default AWSErrorMessage;