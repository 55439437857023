import { useState, useMemo } from 'react';
import styled from 'styled-components';
import {
  WorkPackageRoleType,
  WorkPackageType,
} from '../../../../types/workPackage.type';
import {
  calculateWorkingDays,
  getWeekPeriods,
  WeekPeriod,
} from '../../workPackageHelper';
import { format } from 'date-fns';
import StrypeBg from '../../../../assets/bg/strypes.svg';

interface IPackageCalendarContainer {
  workPackage: WorkPackageType;
}

interface IWeekCollapsed {
  weekNumber: number;
  weekPeriod: WeekPeriod;
}

const WeekCollapsed = ({ weekNumber, weekPeriod }: IWeekCollapsed) => {
  return (
    <StyledWeekCollapsed>
      {format(weekPeriod.startDate, 'MMM')} {format(weekPeriod.startDate, 'dd')}
      -{format(weekPeriod.endDate, 'dd')}
      <div>WK{weekNumber}</div>
    </StyledWeekCollapsed>
  );
};

const WeekExpanded = ({
  weekPeriod,
}: {
  weekPeriod: WeekPeriod;
}): JSX.Element => {
  return (
    <StyledWeekExpanded>
      {format(weekPeriod.startDate, 'MMM')} {format(weekPeriod.startDate, 'dd')}
      -{format(weekPeriod.endDate, 'dd')}
      <StyledWeekExpandedDays>
        {weekPeriod.workingDays.map((day, index) => (
          <span key={index}>{format(day, 'EEEEE')}</span>
        ))}
      </StyledWeekExpandedDays>
    </StyledWeekExpanded>
  );
};

interface IRoleWeekExpanded {
  weekPeriod: WeekPeriod;
  role: WorkPackageRoleType;
}

//this to be used in the updated design
const RoleWeekExpanded = ({
  weekPeriod,
  role,
}: IRoleWeekExpanded): JSX.Element => {
  return (
    <StyledRoleWeekExpanded>
      {weekPeriod.workingDays.map((day, index) => (
        <StyledRoleDay key={index}>
          {role.capacity ? role.capacity / 100 : 0}
        </StyledRoleDay>
      ))}
    </StyledRoleWeekExpanded>
  );
};

interface IRoleWeekCollapsed {
  weekPeriod: WeekPeriod;
  role: WorkPackageRoleType;
}

const RoleWeekCollapsed = ({
  weekPeriod,
  role,
}: IRoleWeekCollapsed): JSX.Element => {
  const workingDays = useMemo(() => {
    if (!role.capacity) {
      return 0;
    }
    return calculateWorkingDays(
      weekPeriod.startDate,
      weekPeriod.endDate,
      role.capacity
    );
  }, [weekPeriod.startDate, weekPeriod.endDate, role.capacity]);
  return <StyledRoleWeekCollapsed>{workingDays} d.</StyledRoleWeekCollapsed>;
};

const PackageCalendarContainer = ({
  workPackage,
}: IPackageCalendarContainer): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);

  const weekPeriods = useMemo(() => {
    if (!workPackage.startDate || !workPackage.endDate) {
      return [];
    }
    return getWeekPeriods(
      new Date(workPackage.startDate),
      new Date(workPackage.endDate)
    );
  }, [workPackage.startDate, workPackage.endDate]);

  return (
    <StyledContainer onClick={() => setIsExpanded(!isExpanded)}>
      <StyledWeekContainer>
        {isExpanded ? (
          <>
            {workPackage?.roles?.items &&
              workPackage.roles.items.length > 0 && (
                <>
                  {weekPeriods.map((weekPeriod, index) => (
                    <WeekCollapsed
                      weekPeriod={weekPeriod}
                      weekNumber={index + 1}
                      key={index}
                    />
                  ))}
                </>
              )}
          </>
        ) : (
          <>
            {weekPeriods.map((weekPeriod, index) => (
              <WeekExpanded weekPeriod={weekPeriod} key={index} />
            ))}
          </>
        )}
      </StyledWeekContainer>
      {/* NO DATA TO BE DISPLAYED */}
      {!workPackage?.roles?.items?.length && (
        <StyledEmptyContainer>
          Add dates to staff this package
        </StyledEmptyContainer>
      )}
      {isExpanded && (
        <>
          {workPackage.roles?.items?.map((role) => (
            <StyledRoleWeekContainer key={role.id}>
              {weekPeriods.map((weekPeriod, index) => (
                <RoleWeekCollapsed
                  key={index}
                  role={role}
                  weekPeriod={weekPeriod}
                  
                />
              ))}
            </StyledRoleWeekContainer>
          ))}
        </>
      )}
      {!isExpanded && (
        <>
          {workPackage.roles?.items?.map((role) => (
            <StyledRoleWeekContainer key={role.id}>
              {weekPeriods.map((weekPeriod, index) => (
                <RoleWeekExpanded
                  key={index}
                  role={role}
                  weekPeriod={weekPeriod}
                />
              ))}
            </StyledRoleWeekContainer>
          ))}
        </>
      )}
    </StyledContainer>
  );
};

const StyledWeekContainer = styled.div`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.elements.line};
  border-bottom: 1px solid ${({ theme }) => theme.elements.line};
  width: fit-content;
  min-width: 100%;
  min-height: 66px;
`;

const StyledEmptyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.elements.line};
`;

const StyledRoleWeekContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.elements.line};
  width: fit-content;
  min-width: 100%;

  background-image: url(${StrypeBg});
`;

const StyledWeekExpanded = styled.div`
  display: flex;
  width: 205px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 64px;
  background-color: white;
  text-align: center;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.palette.gray10};
  line-height: 1.6rem;
  border-right: 1px solid #d2d2d2;
`;

const StyledWeekExpandedDays = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > * {
    min-width: 41px;
    font-size: 1rem;

    letter-spacing: 1px;
    margin-top: ${({ theme }) => theme.spacing[1]};
    line-height: 1em;
    color: ${({ theme }) => theme.palette.gray50};
  }
`;

const StyledRoleDay = styled.div`
  display: flex;
  min-width: 41px;
  min-height: 136px;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-right: 1px solid ${({ theme }) => theme.elements.line};
  border-radius: 1px;

  &:last-child {
    border-right: 1px solid #d2d2d2;
  }
  &:nth-child(2), &:nth-child(3) {
    background: rgb(255, 145, 145);
    
  }
`;

const StyledRoleWeekExpanded = styled.div`
  display: flex;
  background-color: white;
  /* border-right: 1px solid ${({ theme }) => theme.elements.line}; */
`;

const StyledRoleWeekCollapsed = styled.div`
  display: flex;
  min-width: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 136px;
  background-color: white;
  text-align: center;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.palette.gray10};
  line-height: 1.6rem;
  border-right: 1px solid #ebebeb;
  

 
`;

const StyledWeekCollapsed = styled.div`
  display: flex;
  min-width: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* min-height: 72px; */
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.palette.gray10};

  > div {
    font-size: 1rem;
    letter-spacing: 1px;
    margin-top: ${({ theme }) => theme.spacing[1]};
    line-height: 1em;
    color: ${({ theme }) => theme.palette.gray50};
   
  }
`;

const StyledContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.elements.line};
  
  border-right: 1px solid ${({ theme }) => theme.elements.line};
`;

export default PackageCalendarContainer;
