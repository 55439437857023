import { API } from 'aws-amplify';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateWorkPackageRoleMutation, UpdateWorkPackageRoleMutationVariables } from '../../API';
import { updateWorkPackageRole } from '../../graphql/mutations';
import { WorkPackageRoleType } from '../../types/workPackage.type';

export const updatePackageRoleThunk = createAsyncThunk('currentProject/updatePackageRole', async (role: WorkPackageRoleType) => {
    const variables : UpdateWorkPackageRoleMutationVariables = {
        input: {
            id: role.id,
            capacity: role.capacity,
            rolePackagesId: role.rolePackagesId,
            employeePackagesId: role.employeePackagesId
        }
    };
    const apiData = (await API.graphql({ query: updateWorkPackageRole, variables: variables})) as { data: UpdateWorkPackageRoleMutation };    
    const response = apiData.data.updateWorkPackageRole;
    return response;
});
