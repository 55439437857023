import { darken } from 'polished';
import styled from 'styled-components';
import Button from './buttonStyling';

const WhiteButton = styled(Button)`
    background-color: ${({theme})=>theme.palette.white};
    color: ${({theme})=>theme.text.default};
    border: 1px solid ${({theme})=>theme.palette.gray};
    &:focus{
        outline: 3px solid ${({theme})=>theme.palette.primary};
        background-color: ${({theme})=>theme.palette.white};
    }
    &:hover{
        background-color: ${({theme})=>darken(0.1,theme.palette.white)};
    }
`;

export default WhiteButton;