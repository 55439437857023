import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import styled from 'styled-components';
import Button from '../../../components/shared/Button';
import { useModal } from '../../../components/shared/Modal/useModal';
import Panel from '../../../components/shared/Panel';
import { getProjectThunk } from '../../../reducers/currentProject/getProject';
import { RootState, useAppDispatch } from '../../../store';
import { WorkPackageType } from '../../../types/workPackage.type';
import AddWorkPackageModal from '../AddWorkPackage/AddWorkPackageModal';
import WorkPackagesInner from './WorkPackagesInner';
import WorkPackageContainer from '../AddWorkPackage/WorkPackageContainer';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { addWorkPackage } from '../../../reducers/currentProjectReducer';
import { createEmptyPackage } from '../workPackageHelper';




interface IWorkPackageSection {
  workPackages?: WorkPackageType[];
}

const WorkPackageSection2 = ({
  workPackages,
}: IWorkPackageSection): JSX.Element => {
  const { isShown, toggle } = useModal();
  const dispatch = useAppDispatch();

  const projectId = useSelector(
    (state: RootState) => state.currentProject.currentProject?.id
  );

  const toggleHandler = () => {
    if (projectId && isShown) {
      dispatch(getProjectThunk(projectId));
    } else {
      toggle();
    }
  };

  return (
    <>
      <Panel.Container flexDirection="column">
        <Panel.Header>
          <h3>Work packages</h3>
          {workPackages?.length === 0 && (
            <Button.Primary
            type="button"
            onClick={() => {
              dispatch(addWorkPackage(createEmptyPackage()));
           
            }}
          >
            Set up new package
          </Button.Primary>
          )}
          {workPackages?.length !== 0 && (
            <Button.Primary
            type="button"
            onClick={() => {
              dispatch(addWorkPackage(createEmptyPackage()));
           
            }}
          >
            Add package
          </Button.Primary>
          )}
        </Panel.Header>

        {/* {workPackages?.length !== 0 && (
          <>
            {workPackages?.map(
              (workPackage) =>
                workPackage && (
                  <WorkPackagesOverview
                    key={workPackage.id}
                    workPackage={workPackage}
                  />
                )
            )}
          </>
        )} */}

        {workPackages?.map((workPackage, index) => (
          <StyledAccordion TransitionProps={{ unmountOnExit: true }}>
            <StyledAccordionSummary
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <WorkPackageInfo>
                <h4>{workPackage.name}</h4>
                <p>
  {dayjs(workPackage.startDate).format('MMM D, YYYY')} -{' '}
  {dayjs(workPackage.endDate).format('MMM D, YYYY')}
</p>
              </WorkPackageInfo>
            </StyledAccordionSummary>
            <AccordionDetails>
              <WorkPackageContainer
                key={workPackage.id}
                workPackage={workPackage}
                index={index}
              />
            </AccordionDetails>
          </StyledAccordion>
        ))}
        <WorkPackagesInner isShown={isShown} toggle={toggleHandler} />
      </Panel.Container>
      <AddWorkPackageModal isShown={isShown} toggle={toggleHandler} />
    </>
  );
};

const StyledAccordionSummary = styled(AccordionSummary)`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 2.5rem 0rem;
    &.Mui-expanded {
      margin: 0;
    }
  }
  .MuiAccordionSummary-expandIconWrapper {
    background: #d8d8d8;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;

    svg {
      height: 24px;
      width: initial;
      color: #fff;
    }
  }
`;

const StyledAccordion = styled(Accordion)`
  margin-bottom: 1rem;
  &.MuiAccordion-root {
    box-shadow: none;
    border: 1px solid #e6e8ec;
    border-radius: 4px;
    margin: 0 0 2rem;
    &:before {
      display: none;
    }
  }
`;

const WorkPackageInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  h4 {
    margin: 0 0 4px;
  }
  p {
    margin: 0;
    color: #6b7280;
    font-weight: 400;
    font-size: 1.4rem;
  }
`;

export default WorkPackageSection2;
