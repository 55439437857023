import { API } from 'aws-amplify';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateWorkPackageMutation, UpdateWorkPackageMutationVariables } from '../../API';
import { updateWorkPackage } from '../../graphql/mutations';
import { WorkPackageType } from '../../types/workPackage.type';

export const updateWorkPackageThunk = createAsyncThunk('currentProject/updateWorkPackage', async (workPackage: WorkPackageType) => {
    let newWorkPackage = {
        ...workPackage
    };
    delete newWorkPackage.duration;
    delete newWorkPackage.roles;
    const variables : UpdateWorkPackageMutationVariables = {
        input: {
            ...newWorkPackage,
        }
    };
    const apiData = (await API.graphql({ query: updateWorkPackage, variables: variables})) as { data: UpdateWorkPackageMutation };    
    const workPackageResponse = apiData.data.updateWorkPackage;
    return workPackageResponse;
});
