import Wrapper from './HeaderWrapper';
import Navigation from './HeaderNavigation';
import User from './HeaderUser';

const Header = {
  Wrapper,
  Navigation,
  User,
};

export default Header;
