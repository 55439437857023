import styled from 'styled-components';
import StyledIcon from './StyledIcon';
import InfoImage from './../../../assets/icons/information.svg';

const InfoIcon = styled(StyledIcon)`
    mask: url(${InfoImage});
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: ${({theme, color})=>color || theme.palette.gray50};
`;

export default InfoIcon;