import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import elementSize from '../../../types/elementSize';

const buttonTextSizes = {
  small: '1.2rem',
  medium: '1.4rem',
  large: '1.6rem',
};

const buttonPaddingSizes = {
  small: '0.7em 1em',
  medium: '0.8em 1.1em',
  large: '0.9em 1.5em',
};

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  readonly size?: elementSize;
  readonly fullWidth?: boolean;
  readonly invert?: boolean;
  readonly icon?: ReactNode;
  readonly loading?: boolean;
  children?: ReactNode;
}

export interface IButtonStyled {
  readonly $fullWidth?: boolean;
  readonly $invert?: boolean;
  readonly $size?: elementSize;
  readonly $loading?: boolean;
}

const ButtonStyle: React.FC<IProps> = ({
  icon,
  size,
  loading,
  fullWidth,
  invert,
  children,
  ...rest
}) => {
  return (
    <ButtonStyled
      $loading={loading}
      $size={size}
      $fullWidth={fullWidth}
      $invert={invert}
      {...rest}
    >
      {icon && <IconContainer marginRight={!!children}>{icon}</IconContainer>}
      <>{children}</>
    </ButtonStyled>
  );
};

interface IIconContainer {
  marginRight?: boolean;
}

const IconContainer = styled.div<IIconContainer>`
  margin-right: ${({ marginRight, theme }) =>
    marginRight ? theme.spacing[1] : '0px'};
`;

const ButtonStyled = styled.button<IButtonStyled>`
  font-weight: 500;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  min-height: 44px;
  align-items: center;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  font-size: ${({ $size }) => buttonTextSizes[$size ? $size : 'medium']};
  padding: ${({ $size }) => buttonPaddingSizes[$size ? $size : 'medium']};
  ${({ $size }) => buttonPaddingSizes[$size ? $size : 'large']};
  border: none;
  opacity: ${({ $loading }) => ($loading ? 0.5 : 1)};
  color: white;
  cursor: ${({ $loading }) => ($loading ? 'wait' : 'pointer')};
  line-height: 1.1em;
  transition: ${({ theme }) => theme.elements.transitions};
  a:active {
    outline: none;
  }
  :disabled {
    color: ${({ theme }) => theme.palette.gray50};
    pointer-events: none;
  }
  &:focus {
    outline-offset: 3px;
  }
`;

export default ButtonStyle;
