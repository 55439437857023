import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Icon from '../../../../components/shared/Icon';
import { RootState } from '../../../../store';
import { WorkPackageRoleType } from '../../../../types/workPackage.type';
import { calculateWorkingDays } from '../../workPackageHelper';
import PackageTotalsTooltip from './PackageTotalsTooltip';

export type RoleTotalType = {
    role: WorkPackageRoleType['id'],
    cost: number,
    days: number
}

export type TotalsType = {
    days: number,
    cost: number,
    roles: RoleTotalType[]
}

const PackageTotals = ()=>{
    const project = useSelector((state: RootState) => state.currentProject.currentProject);
    const defaultRoles = useSelector((state: RootState) => state.currentProject.defaultRoles);

    const totals = useMemo(()=>{
        
        let totals: TotalsType = {
            days: 0,
            cost: 0,
            roles: []
        };

        project?.workPackages.items?.forEach(workPackage => {
                workPackage.roles?.items?.forEach(role=>{
                    if (workPackage.startDate && workPackage.endDate && role.capacity){
                        const defaultRole = defaultRoles.find(r=>r.id === role.rolePackagesId);    
                        if (defaultRole){
                            let roleCostTotal = 0;
                            const roleDaysTotal = calculateWorkingDays(new Date(workPackage.startDate), new Date(workPackage.endDate), role.capacity);
                            roleCostTotal = defaultRole.defaultRate.value * roleDaysTotal;
                            const existingRoleTotals = totals.roles.find(r=>r.role === role.rolePackagesId);
                            if (existingRoleTotals){
                                existingRoleTotals.cost += roleCostTotal;
                                existingRoleTotals.days += roleDaysTotal;
                            }
                            else{
                                totals.roles.push({
                                    role: defaultRole?.id,
                                    days: roleDaysTotal,
                                    cost: roleCostTotal
                                });
                            }
                        }

                    }
                });
        });
        totals.roles.forEach(role=>{
            totals.days += role.days;
            totals.cost += role.cost; 
        });
        return totals;
    }, [project?.workPackages.items, defaultRoles]);

    return (
        <>
            {!!project &&
                <StyledPackageTotals>
                    <span>
                        Total planned:
                    </span>
                    <StyledEmphasisText>
                        {totals.days} days / €{totals.cost / 1000}k
                    </StyledEmphasisText>
                    <Icon.InfoSummary />
                    <StyledPackageTotalsTooltip>
                        <PackageTotalsTooltip defaultRoles={defaultRoles} totals={totals} />
                    </StyledPackageTotalsTooltip>
                </StyledPackageTotals>
            }
        </>
    );
};



const StyledPackageTotalsTooltip = styled.div`
    position: absolute;
    right: -5px;
    display: none;
    top: calc(100% + 1em);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.07);
    border: 1px solid ${({theme})=>theme.elements.line};
    z-index: 1;
    &:after{
        width: 10px;
        border: 1px solid ${({theme})=>theme.elements.line};
        height: 10px;
        content: '';
        position: absolute;
        right: 5px;
        top: -5px;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.07);
        transform: rotate(45deg);
        background-color: ${({theme})=>theme.background.secondary};
    }
`;

const StyledPackageTotals = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    margin-left: auto;
    font-size: 1.2rem;
    > span{
        color: ${({theme})=>theme.palette.gray50};
        margin-right: 0.5em;
    }
    > ${Icon.InfoSummary}{
        margin-left: 0.4em;
    }
    &:hover{
        ${StyledPackageTotalsTooltip}{
            display: block;
        }
    }
`;


const StyledEmphasisText = styled.div`
    display: inline-block;
    color: ${({theme})=>theme.palette.primary};
`;

export default PackageTotals;

