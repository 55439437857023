import Elements from '../../../components/shared/Elements';

const BudgetTab = () => {
  return (
    <>
      <Elements.NoResults
        title="No budget to be found"
        subtitle="Try adjusting your search or filter to find what you are looking for."
      />
    </>
  );
};

export default BudgetTab;
