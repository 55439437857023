import { darken } from 'polished';
import styled from 'styled-components';
import Button from './buttonStyling';

const ButtonDanger = styled(Button)`
    background-color: ${({theme})=>theme.palette.danger};
    color: white;
    &:focus{
        outline: 3px solid ${({theme})=>theme.palette.danger};
        background-color: ${({theme})=>theme.palette.danger};
    }
    &:hover{
        background-color: ${({theme})=>darken(0.1,theme.palette.danger)};
    }
`;

export default ButtonDanger;