import { css } from 'styled-components';

const typography = css`
    html {
        font-size: 62.5%;
        font-family: 'Lexend', sans-serif;
    }

    body{
        font-size: 1.5rem;
    }


    h1{
        font-size: 3.2rem;
        font-weight: 600;
        line-height: 1.4;
    }

    h2{
        font-style: normal;
        font-weight: 600;
        font-size: 2.8rem;
        line-height: 1.4;
    }

    h3{
        font-style: normal;
        font-weight: 500;
        font-size: 2.0rem;
        line-height: 1.4;
    }
    
    h4{
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.4;
    }

    h5{
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.4;
    }

    p{
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.4em;
    }

    b{
        font-weight: 500;
    }

    label{
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1em;
    }
`;
export default typography;