import {  FC } from 'react';
import { StyledModal, Content } from './modal.style';
import { ModalContainer, IModalProps } from './ModalContainer';

const FullScreen : FC<IModalProps> = ({toggle, isShown, children})=>{
    return (
        <>
            <ModalContainer isShown={isShown} toggle={toggle} >
                <StyledModal $fullscreen={true}>
                    <Content>{children}</Content>
                </StyledModal>
            </ModalContainer>
        </>
    );
};

export default FullScreen;
