import styled from 'styled-components';

export const StyledHeader = styled.div`
    font-weight: 400;
    color: ${({theme})=>theme.text.secondary};
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid ${({theme})=>theme.elements.line};
    align-items: center;
    letter-spacing: 1px;
    display: flex;
    font-size: 1.2rem;
`;

export const StyledSummary = styled.div`
    color: #4B4B4B;
    font-size: 1.4rem;
    font-weight: 700;
`;

export const StyledRole = styled.div`
color: #4B4B4B;
font-weight: 400;
`;

export const StyledTable = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    >:nth-child(4n+1){
        justify-content: flex-start;
    }
    >*{
        justify-content: flex-end;
        display: flex;
    }
    >*:not(${StyledHeader}){
        font-size: 1.4rem;
        line-height: 2.0rem;
        padding-top: 6px;
        padding-bottom: 6px;

    }
`;


export const StyledSummaryBlock = styled.div`
    font-size: 1.2rem;
    line-height: 1.6rem;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    position: relative;
    color: ${({theme})=>theme.palette.gray35};
    b{
        color: ${({theme})=>theme.text.default};
    }

    margin-bottom: ${({theme})=>theme.spacing[1]};
    &:last-child{
        margin-bottom: 0;
    }
`;

export const StyledPackageSummaryTooltip = styled.div`
    position: absolute;
    left: 50%;
    bottom: calc(100% + 1em);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.07);
    border: 1px solid ${({theme})=>theme.elements.line};

    z-index: 1;
    &:after{
        width: 18px;
        border: 1px solid ${({theme})=>theme.elements.line};
        height: 18px;
        content: '';
        position: absolute;
        left: 22px;
        bottom: -9px;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.07);
        transform: rotate(45deg);
        background-color: ${({theme})=>theme.background.secondary};
    }
`;

export const StyledPackageSummaryTooltipContainer  = styled.div`
    display: block;
    width: 100%;
    min-width: 470px;
    height: 100%;
    background-color: ${({theme})=>theme.background.secondary};
    padding: 0 ${({theme})=>theme.spacing[2]} ${({theme})=>theme.spacing[1]};
    z-index: 2;
    position: relative;
`;
