import styled from 'styled-components';
import IconStyled from './StyledIcon';
import UsersImage from './../../../assets/icons/users.svg';

const UsersIcon = styled(IconStyled)`
    background-image: url(${UsersImage});
    background-size: contain;
    background-repeat: no-repeat;
`;

export default UsersIcon;