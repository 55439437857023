import styled from 'styled-components';

interface IBadge{
    status: 'success' | 'warning' | 'danger'
}

const Badge = styled.div<IBadge>`
    background-color: ${({theme, status})=>theme.background[status]};
    color: ${({theme, status})=>theme.palette[status]};
    border-radius: 4px;
    display: inline-block;
    font-size: 1.2rem;
    line-height: 1em;
    padding: 0.5em 0.9em;
`;

export default Badge;