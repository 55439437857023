import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import loadingState from '../types/loadingState.type';
import { currentEmployeeType } from '../types/employee.type';
import { deleteEmployeeThunk } from './currentEmployee/deleteEmployee';
import { getEmployeeThunk } from './currentEmployee/getEmployee';
import { unavailableType } from '../types/unavailable.type';
import { addUnavailablePeriodThunk } from './currentEmployee/addUnavailablePeriod';

export interface EmployeeState {
  currentEmployee: currentEmployeeType | null;
  employeeStatus: loadingState;
}

const initialState: EmployeeState = {
  currentEmployee: null,
  employeeStatus: 'loading',
};

export const currentEmployeeSlice = createSlice({
  name: 'currentEmployee',
  initialState,
  reducers: {
    addUnavailablePeriod: (state, action: PayloadAction<unavailableType>) => {
      const newUnavailablePeriod = action.payload;
      if (
        newUnavailablePeriod &&
        state.currentEmployee &&
        state.currentEmployee.unavailable.items
      ) {
        // Create a new state with the new item
        state.currentEmployee.unavailable.items = [
          ...state.currentEmployee.unavailable.items,
          newUnavailablePeriod,
        ];
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEmployeeThunk.pending, (state, action) => {
        state.employeeStatus = 'loading';
      })
      .addCase(getEmployeeThunk.fulfilled, (state, action) => {
        console.log(action.payload); // Add this line
        if (action.payload) {
          state.employeeStatus = 'succeeded';
          state.currentEmployee = action.payload;
        }
      })
      .addCase(getEmployeeThunk.rejected, (state, action) => {
        state.employeeStatus = 'failed';
      })
      .addCase(deleteEmployeeThunk.pending, (state, action) => {
        state.employeeStatus = 'loading';
      })
      .addCase(deleteEmployeeThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.employeeStatus = 'succeeded';
          state.currentEmployee = null;
        }
      })
      .addCase(deleteEmployeeThunk.rejected, (state, action) => {
        state.employeeStatus = 'failed';
      })
      .addCase(addUnavailablePeriodThunk.pending, (state, action) => {
        state.employeeStatus = 'loading';
      })

      .addCase(addUnavailablePeriodThunk.fulfilled, (state, action) => {
        if (action.payload) {
          const payload = action.payload as unavailableType;
          const newUnavailablePeriod: unavailableType = {
            ...payload,
            startDate: payload?.startDate || '',
            endDate: payload?.endDate || '',
          };
          state.currentEmployee?.unavailablePeriods?.items?.push(
            newUnavailablePeriod
          );
        }
      })
      .addCase(addUnavailablePeriodThunk.rejected, (state, action) => {
        state.employeeStatus = 'failed';
      });
  },
});

export default currentEmployeeSlice.reducer;
