import { useMemo } from 'react';
import styled from 'styled-components';
import { defaultRoleType } from '../../../../types/defaultRole.type';
import { formatCurrency, getRoleName } from '../../workPackageHelper';
import { RoleTotalType } from './PackageTotals';

interface IPackageTotalsRole{
    role: RoleTotalType,
    defaultRoles: defaultRoleType[]
}

const PackageTotalsRole = ({role, defaultRoles}: IPackageTotalsRole)=>{
    const roleName = useMemo(()=>getRoleName(role.role, defaultRoles), [role.role, defaultRoles]);
    return (
        <>
            <StyledRole>
                {roleName}
            </StyledRole>

            <StyledRole>
                {role.days}
            </StyledRole>

            <StyledRole>
                {formatCurrency(role.cost)}
            </StyledRole>
        </>
    );
};

const StyledRole = styled.div`
    color: #4B4B4B;
    font-weight: 400;
`;

export default PackageTotalsRole;