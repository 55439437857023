import React from 'react';
import styled from 'styled-components';
import ReactSelect from 'react-select';
import selectorImage from '../../../assets/icons/selector.svg';
import checkedIcon from '../../../assets/icons/check.svg';

const Select = ({...props})=> {
  return (
    <StyledReactSelect {...props}
    classNamePrefix={'Select'} />
    );
};

interface StyleProps{
    $borderless: boolean
}

const StyledReactSelect = styled<StyleProps & any>(ReactSelect)`
    .Select__control{
        border-radius: 2px;
        border: 1px solid ${({theme, $borderless})=>$borderless ? 'transparent' : theme.palette.gray};
    }
    .Select__menu{
        border-radius: 2px;
    }
    .Select__indicator-separator{
        display: none;
    }
    .Select__dropdown-indicator{
        background-image: url(${selectorImage});
        background-position: center;
        background-repeat: no-repeat;
        width: 30px;
        > svg{
            display: none;
        }
    }
    .Select__option{
        font-size: 1.4rem;
        text-align: left;
    }
    .Select__option--is-selected{
        background-color: ${({theme})=>theme.background.secondary};
        color: ${({theme})=>theme.text.default};
        font-weight: bold;
        padding-right: 40px;
        position: relative;
        &:after{
            position: absolute;
            content: "";
            width: 30px;
            right: 0;
            top: 0;
            background-repeat: no-repeat;
            background-position: center left 7px;
            height: 100%;
            background-image: url(${checkedIcon});
        }
    }
`;

export default Select;