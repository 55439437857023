import { css } from 'styled-components';
import selectorImage from '../assets/icons/selector.svg';

const datepickerStyle = css`

    .react-datepicker-wrapper{
        font-size: 1.6rem;
    }
    .react-datepicker{
        font-size: 1.4rem;
        box-shadow: 0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07), 0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13);
        border: none;
        font-family: 'Lexend', sans-serif;
        padding-bottom: 60px;
    }
    .react-datepicker__current-month{
        font-size: 1.4rem;
    }
    .react-datepicker__day--outside-month{
        color: ${({theme})=>theme.text.secondary};

    }
   
    .react-datepicker__day{
        width: 4rem;
        height: 4rem;
        &:hover{
            border-radius: 50%;
            background-color: ${({theme})=>theme.background.primary};
        }
    }
    .react-datepicker__day.react-datepicker__day--selected{
        border-radius: 50%;
        background-color: ${({theme})=>theme.palette.primary};
        font-weight: 400;
        &:hover{
            border-radius: 50%;
            background-color: ${({theme})=>theme.palette.primary};
        }
    }
    .react-datepicker__week{
        display: flex;
        flex-wrap: wrap;
    }
    .react-datepicker__day{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .react-datepicker__triangle{
        display: none;
    }
    .react-datepicker__header{
        background: transparent;
        border: none;
        padding-top: 10px;
        padding-bottom: 0;
        > div{
            margin-top: 5px !important;
            margin-bottom: 0 !important;
        }
    }
    .react-datepicker__day-names{
        display: flex;
        width: 100%;
        margin: 0;
        font-weight: bold;
        padding: 0.4rem;
        padding-bottom: 0;
    }
    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
        justify-content: center;
        align-items: center;
        display: flex;
        width: 4rem;
        height: 4rem;
    }
    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
        border-radius: 50%;
        background-color: ${({theme})=>theme.background.primary};
        color: black;
    }
    .react-datepicker__month{
        margin-top: 0;
    }
    .react-datepicker__input-container{
        width: auto;
        position: relative;
        &:after{
            content: '';
            position: absolute;
            right: 0;
            height: 100%;
            width: 30px;
            background-image: url(${selectorImage});
            background-size: 8px;
            background-repeat: no-repeat;
            background-position: center;
        }
        input{
            width: 100%;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 1.4;
            position: relative;
            padding: 0.5em 30px 0.5em 0.7em;
            border: 1px solid ${({theme})=>theme.palette.gray};
        }
    }
`;

export default datepickerStyle;