import { FC } from 'react';
import styled from 'styled-components';
import Hr from './Hr';

const HrContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    > ${Hr}{
        flex-grow: 1;
    }
`;

const HrTextContainer = styled.div`
    display: flex;
    color: ${({theme})=>theme.text.secondary};
    padding: 0 ${({theme})=>theme.spacing[1]};
`;

const HrText: FC = ({children})=>(
    <HrContainer>
        <Hr />
        <HrTextContainer>
            {children}
        </HrTextContainer>
        <Hr />
    </HrContainer>
);

export default HrText;